import { createApp, markRaw } from 'vue';
import i18n from './i18n';
import { createPinia } from 'pinia';
import CKEditor from '@ckeditor/ckeditor5-vue';
import version from './version'

import vSelect from 'vue-select';

import App from './App.vue';
import { router } from './router';
import * as Sentry from "@sentry/vue";
import { useAuthStore } from './stores';

import Slideout from '@hyjiacan/vue-slideout'
import '@hyjiacan/vue-slideout/dist/slideout.css'
import Notifications from '@kyvg/vue3-notification'

import 'vue-select/dist/vue-select.css';
import { useLoading } from '@/loader';
import '@/loader/vue3-loading-overlay.css';

//Host var
window.api = import.meta.env.VITE_VUE_APP_API;
window.client = import.meta.env.VITE_VUE_APP_API_HOST;
window.api_version = import.meta.env.VITE_VUE_APP_API_VERSION;

//Axios
/*
axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.baseURL = window.api;
*/


const app = createApp(App);
app.use(i18n);
// Sentry



Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      //  
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        //tracePropagationTargets: ["localhost", "my-site-url.com", /^\//],
      }),
      new Sentry.Replay()
      // 
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    release: import.meta.env.VITE_SENTRY_PROJECT + "@" + version
});


app.component('v-select', vSelect);
const pinia = createPinia();
pinia.use(({ store }) => {
    store.$router = markRaw(router);
  });
app.use(pinia);
app.use(router);
app.use(CKEditor);
app.use(Notifications)
app.use(Slideout, {
    size: '65%',
    renderWhenVisible: true
})
const loader = useLoading();
app.config.globalProperties.$loading = loader;

// Auth Store
const authStore = useAuthStore();

app.directive('can', {
    beforeUpdate(el, binding, vnode) {
        if(authStore.user) {
            var permissions = authStore.user.perms;
    
            if(permissions.includes(binding.value)) {
                return vnode.el.hidden = false;
            } else {           
                return vnode.el.hidden = true;
            }
        }
    }
});


app.directive('role', {
    beforeMount(el, binding, vnode) {
        if(authStore.user) {
            var roles = authStore.user.roles;

            if(roles.includes(binding.value)) {
                return vnode.el.hidden = false;
            } else {           
                return vnode.el.hidden = true;
            }
        }
        return vnode.el.hidden = true;
    }
});


app.mount('#app');
