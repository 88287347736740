import { defineStore } from 'pinia';
import { onActivated, ref } from 'vue';
import { fetchWrapper } from '../_helpers/fetch-wrapper';
const baseUrl = `${import.meta.env.VITE_API_URL}/journal`;

const publishedListCatTree = JSON.parse(localStorage.getItem('publishedListCatTree'))


export const usePublishedStore = defineStore({
    id: 'published-state',
    state: () => ({
        categoryTree: {
            opened: publishedListCatTree ? publishedListCatTree.opened : []
        },
        isPublishedLoading: ref(''),
        publishedList: ref({}),
        draftDetail: Object,
        draftDetailImages: new Object({
            images: new Array(0),
        }),
        draftDetailFiles: new Object({
            files: new Array(0),
        }),
        remoteDetail: ref({}),
        publishing: ref({}),
        draftDetailCats: Object,
        publishingCats: new Array(0),
        publishUpload: ref({}),
        selectedLanguage: ref(0),
    }),
    actions: {
        initPublish() {
            this.publishing = ref({})
        },
        setPublishedLoading(isLoading) {
            this.isPublishedLoading = isLoading;
        },
        async fetchPublishedNewspaper(newspaper_id, offset=0, limit=10, search="") {
            this.publishedList = {};
            this.setPublishedLoading(true);
            
            //const publishedList = await fetchWrapper.get(`${baseUrl}/published?newspaper=${newspaper_id}&page=${(offset / limit + 1)}&limit=${limit}`);
            const response = await fetchWrapper.get(`${baseUrl}/allnews?newspaper=${newspaper_id}&page=${(offset / limit + 1)}&limit=${limit}&search=${search}&language_id=${this.selectedLanguage}&type=published`);
            this.setPublishedLoading(false);
            //this.publishedList = Object.keys(publishedList.data).length > 0 ? publishedList.data : {};
            this.publishedList = response.data;
            this.pager = response.links;
            
        },
        async publishPublished() {
            // First save Draft
            this.savePublished();
            
        },
        async saveAndClean() {
            await this.savePublished()
            // Reset local store
            this.resetLocalStore();
        },
        async saveRemoteAndClean(newspaper_id) {
            const resp = await this.saveRemote(newspaper_id);
            // Reset local store
            this.resetLocalStore();
            return resp.data;
        },
        async uploadImage(file, newspaper_id) {
            let formData = new FormData();
            formData.set('image', file);
            return new Promise( ( resolve, reject ) => {
                fetchWrapper.post(`${baseUrl}/published/remote/${newspaper_id}/${this.remoteDetail.uid}/inline`, formData).then( (resp) => {
                    resolve({
                        default: '/published-images/' + resp.path
                    });
                }).catch( (reason) => {
                    reject(reason);
                });
            });
        },
        async uploadRemoteImage(file, newspaper_id) {
            let formData = new FormData();
            formData.set('image', file);
            return new Promise( ( resolve, reject ) => {
                console.log("Remote Detail", this.remoteDetail);
                fetchWrapper.post(`${baseUrl}/published/remote/${newspaper_id}/${this.remoteDetail.uid}/image`, formData).then( (resp) => {
                    resolve(resp);
                }).catch( (reason) => {
                    reject(reason);
                });
            });
        },
        async saveRemote(newspaper_id) {
            // Save new images
            this.remoteDetail.tx_damnews_dam_images = [];
            for ( const image of this.draftDetailImages.images) {
                if(!image.remote_id) {
                    const resp = await this.uploadRemoteImage(image.orig, newspaper_id);
                    this.remoteDetail.tx_damnews_dam_images.push({
                        uid: resp.data.uid,
                        file_path: resp.data.file_path,
                        file_name: resp.data.file_name,
                        type: 'remote',
                        remote_id: resp.data.backend_server_id + '-' + resp.data.uid,
                        url: resp.data.file_path + resp.data.file_name,
                        caption: image.caption
                    });
                } else {
                    this.remoteDetail.tx_damnews_dam_images.push(image)
                }
            }

            // Save new attachments
            this.remoteDetail.tx_damnews_dam_media = [];
            for ( const file of this.draftDetailFiles.files) {
                if(!file.remote_id) {
                    const resp = await this.uploadRemoteImage(file.orig, newspaper_id);
                    this.remoteDetail.tx_damnews_dam_media.push({
                        uid: resp.data.uid,
                        file_path: resp.data.file_path,
                        file_name: resp.data.file_name,
                        type: 'remote',
                        remote_id: resp.data.backend_server_id + '-' + resp.data.uid,
                        url: resp.data.file_path + resp.data.file_name,
                    });
                } else {
                    this.remoteDetail.tx_damnews_dam_media.push(file);
                }
            }

            // Save news
            const resp = await fetchWrapper.put(`${baseUrl}/published/remote/${newspaper_id}/${this.remoteDetail.uid}`, this.remoteDetail);
            return resp;
        },
        async savePublished() {
            let resp;
            this.draftDetail.category = this.lastCat;
            if(this.draftDetail.id) {
                
                resp = await fetchWrapper.put(`${baseUrl}/published/${this.draftDetail.id}`, this.draftDetail);
            } else {
                resp = await fetchWrapper.post(`${baseUrl}/published`, this.draftDetail);
                if(resp.status == "success") {
                    this.draftDetail.id = resp.data.id;
                }
            }
            
            let sorting = [];
            let waiting = [];
            this.draftDetailImages.images.forEach( (element, index) => {
                if(element.id) {
                    // Saved image
                    sorting.push({"id":element.id, "sorting": index});
                    element.sorting = index;
                } else if (element.type == "remote") {
                    const prom = fetchWrapper.post(`${baseUrl}/published/${this.draftDetail.id}/images`, element).then(data => {
                        sorting.push({"id": data.data.id, "sorting": index});
                        element.id = data.data.id;
                        element.sorting = index;
                    });
                    waiting.push(prom);
                } else {
                    // Added image
                    let formData = new FormData
                    //let url = URL.createObjectURL(files[i])
                    formData.set('image', element.orig)
                    const prom = fetchWrapper.post(`${baseUrl}/published/${this.draftDetail.id}/images`, formData).then(data => {
                        sorting.push({"id": data.data.id, "sorting": index});
                        element.id = data.data.id;
                        element.sorting = index;
                    });
                    waiting.push(prom);
                }
            });
            
            let that = this;
            await Promise.all(waiting).then((values) => {
                fetchWrapper.put(`${baseUrl}/published/${that.draftDetail.id}/images`, {"sorting":sorting, "deleted": that.draftDetailImages.deleted_media})
            });

            if(resp.status == "success") {
                this.publishedList[this.draftDetail.id] = this.draftDetail;
            }

            // Attachment files
            sorting = [];
            waiting = [];
            this.draftDetailFiles.files.forEach( (element, index) => {
                if(element.id) {
                    // Saved file
                    sorting.push({"id":element.id, "sorting": index});
                    element.sorting = index;
                } else if (element.type == "remote") {
                    const prom = fetchWrapper.post(`${baseUrl}/published/${this.draftDetail.id}/files`, element).then(data => {
                        sorting.push({"id": data.data.id, "sorting": index});
                        element.id = data.data.id;
                        element.sorting = index;
                    });
                    waiting.push(prom);
                } else {
                    // Added file
                    let formData = new FormData
                    //let url = URL.createObjectURL(files[i])
                    formData.set('file', element.orig)
                    const prom = fetchWrapper.post(`${baseUrl}/published/${this.draftDetail.id}/files`, formData).then(data => {
                        sorting.push({"id": data.data.id, "sorting": index});
                        element.id = data.data.id;
                        element.sorting = index;
                    });
                    waiting.push(prom);
                }
            });

            await Promise.all(waiting).then((values) => {
                fetchWrapper.put(`${baseUrl}/published/${that.draftDetail.id}/files`, {"sorting":sorting, "deleted": that.draftDetailFiles.deleted_media})
            });

        },
        async fetchRelatingRemote(newspaper_id, offset=0, limit=10, search="", except=[], types="", language_id = 0, quickfilter = "all") {
            return new Promise((resolve, reject) => {
                fetchWrapper.get(`${baseUrl}/remote/${newspaper_id}?page=${(offset / limit + 1)}&limit=${limit}&search=${search}&language_id=${language_id}&except=${except}&type=${types}&quickfilter=${quickfilter}`).then((response) => {
                    resolve(response);
                }).catch((reason) => {
                    reject("Impossibile caricare l'elenco delle notizie dal server. Codice errore: " + reason);
                }).finally(() => {
                });
            });
        },
        async fetchPublishedRemoteList(news_id) {
            const news = await fetchWrapper.get(`${baseUrl}/published?ids[]=${news_id}`);
            return news;
        },
        async fetchRemoteDetail(newspaper_id, remote_id) {
            const news = await fetchWrapper.get(`${baseUrl}/remote/${newspaper_id}/${remote_id}`);
            this.remoteDetail = news.data;
            //this.remoteDetail.published_id = published_id;
            this.draftDetailImages.images = this.remoteDetail['dam_images'] || new Array(0);
            this.draftDetailFiles.files = this.remoteDetail['dam_media'] || new Array(0);
            return this.remoteDetail;
        },
        async fetchRemotePublishedDetail(published_id) {
            const news = await fetchWrapper.get(`${baseUrl}/published/remote/${published_id}`);
            this.remoteDetail = news.data;
            this.remoteDetail.published_id = published_id;
            this.draftDetailImages.images = this.remoteDetail['dam_images'] || new Array(0);
            this.draftDetailFiles.files = this.remoteDetail['dam_media'] || new Array(0);
            return this.remoteDetail;
        },
        async fetchPublishedDetail(news_id) {
            this.resetLocalStore();
            const news = await fetchWrapper.get(`${baseUrl}/published?ids[]=${news_id}`);
            let dates = ['datetime', 'starttime', 'endtime', 'boxed_from', 'boxed_to'];
            dates.forEach(date => {
                if(!news.data[news_id][date]) {
                    news.data[news_id][date] = undefined;
                } else {
                    news.data[news_id][date] = new Date(news.data[news_id][date]);
                }
            });

            //this.newsDetail =  news.data.original.data[parts[0] + "-" + parts[1]][news_id].data['news'][news_id];
            this.draftDetail =  news.data[news_id];

            this.draftDetailImages.images = news.data[news_id]['image'] || new Array(0);
            this.draftDetailCats = news.data[news_id]['category'];
            this.draftDetail.newspaper = this.$router.currentRoute.value.params.newspaperid;
            // Filter out PublishedNews on different Newspaper
            this.draftDetail.publishednews = news.data[news_id].publishednews.filter( (value, index) => {
                return value.backend_server_id + "-" + value.newspaper_id == this.$router.currentRoute.value.params.newspaperid;
            }, this);
        },
        async previewPublished(news) {
            const preview = await fetchWrapper.get(`${baseUrl}/news/preview/${news.backend_server_id}-${news.newspaper_id}-${news.remote_id}`);
            return preview.data.content;
        },
        async publish() {
           let resp = await fetchWrapper.post(`${baseUrl}/published/${this.draftDetail.id}/publish`, {publishing: this.publishing, upload: this.publishUpload});
           return resp;
        },
        setDetail(news) {
            debugger;
            this.draftDetail = news;
        },
        persistState() {
            localStorage.setItem('publishedListCatTree', JSON.stringify(this.categoryTree));
        },
        resetLocalStore() {
            this.draftDetail = new Object;
            this.draftDetailImages = new Object({
                images: new Array(0),
                deleted_media: new Array(0),
            });
            this.draftDetailCats = new Object;
        }
    }
});