<script setup>
import FolderTree from "./FolderTree.vue"
import { useFilemountStore } from "../../stores/filemount.store";
import { onMounted, onBeforeMount, getCurrentInstance, nextTick } from "vue";
import Lazy from "../Lazy.vue";

const filemountStore = useFilemountStore();

onBeforeMount(() => {
    const { emit } = getCurrentInstance();
    emit('beforemount');
});

onMounted(() => {
    const { emit } = getCurrentInstance();
    emit('mounted');
});

</script>

<template>
    <li :class="(isSelected ? 'folder-item selected' : 'folder-item')">
        <i v-if="folder.child?.length" :class="(isOpening ? 'fas fa-spinner fa-spin' : (folder.opened ? 'far fa-folder-open mr-1' : 'far fa-folder-closed mr-1'))" @click="toggleStatus"></i>
		<i v-else class="far fa-folder mr-1"></i>
        <span class="folder" @click="folderSelect(folder.path)">{{ folder.name }}</span>
        <template v-if="(folder.opened)">
            <Lazy><FolderTree @beforemount="childBeforeMount" @mounted="childMounted" :checkSelected="checkSelected"  :baseUrl="baseUrl" :parentPath="path" :folders="folder.child" :level=level+1  :filemount_id="filemount_id" v-bind:opened=folder.opened @folderselect="childFolderSelect" @opening="(status) => childOpening(status)" /></Lazy>
        </template>
    </li>
</template>

<script>
export default {
    data() {
        return {
            isOpening: false,
            childMounting: 0,
        };
    },
    props: {
        folder: Object,
        level: Number,
        filemount_id: String,
        opened: Boolean,
        parentPath: String,
        baseUrl: String,
        checkSelected: {
            type: Function,
            default: null,
        }
    },
    emits: [
        "opening",
        "beforemount",
        "mounted",
        "folderselect",
    ],
    computed: {
        isSelected() {
            if (this.checkSelected) {
                return this.checkSelected(this.path);
            }
            const filemountStore = useFilemountStore();
            return filemountStore.lastPath == this.path;
        },
        path() {
            return this.parentPath + "/" + this.folder.name;
        }
    },
    methods: {
        async childBeforeMount() {
            this.childMounting++;
        },
        async childMounted() {
            this.childMounting--;
            if (this.childMounting == 0) {
                this.isOpening = false;
                this.$emit("opening", this.isOpening);
            }
        },
        childOpening(status) {
            this.$emit("opening", status);
        },
        async toggleStatus(event) {
            if (!this.folder.opened) {
                this.isOpening = true;
                this.$emit("opening", this.isOpening);
            }
            this.folder.opened = !this.folder.opened;
        },
        folderSelect() {
            this.$emit("folderselect", this.path);
        },
        childFolderSelect(path) {
            this.$emit("folderselect", path);
        }
    },
    components: { Lazy }
}
</script>
