<script setup>

import { useRoute } from 'vue-router'
import { usePublishedStore, useDraftStore } from '@/stores';
import { useNewsPaperStore } from '../../stores';
import { storeToRefs } from 'pinia';

import DamImages from '../../components/newspaper/DamImages.vue';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { useLoading } from '@/loader';
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
import enabledConfig from '@/ckeditor5/config/enabled';

const route = useRoute() 
const publishedStore = usePublishedStore();
const newsPaperStore = useNewsPaperStore();
const { isPublishedLoading, draftList, draftDetail, draftDetailImages } = storeToRefs(publishedStore);

</script>

<template>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>News</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="/">Home</a></li>
              <li class="breadcrumb-item active">News</li>
            </ol>
          </div>
        </div>
      </div>
      
      <div class="row">
            <div class="col-sm-6 mb-2 ml-2">
                <RouterLink v-if="draftDetail.publishednews" class="btn btn-primary" :to="{ name: 'remote-edit', params: { newspaperid: route.params.newspaperid, id: draftDetail.publishednews[0].id } }">
                <i class="fas fa-edit"></i> Modifica pubblicazione su {{ newsPaperStore.getNewspaperName(route.params.newspaperid) }}
                </RouterLink>
            </div>
     </div>
      
    </section>
    
    <section class="content">
        <div class="container-fluid">
            
            <div class="card card-default">
                <form name="published-edit" @submit.prevent class="m-3">
                   
                        <div v-if="draftDetail.langInfo  && draftDetail.langInfo.length > 1" class="form-group">
                            <div class="row">
                                <div class="col-xs-4 ml-2">
                                    <label for="lang">Lingua</label>
                                    <v-select :modelValue="draftDetail.language_id" :options="availableLang()" @option:selected="(option) => onLangSelected(option)" label="title" :reduce="(el) => el.uid" >
                                        <template #option="{title, suffix}">
                                            {{ title }}{{ suffix }}
                                        </template>
                                        <template #selected-option="{title, suffix}">
                                            {{ title }}{{ suffix }}
                                        </template>
                                    </v-select>
                                </div>
                            </div>
                        </div>
                      
                    <div class="form-group">
                        <label for="title">Titolo</label>
                        <input disabled type="text" class="form-control" id="title" v-model="draftDetail.title">
                    </div>
                    
                    <div class="form-group">
                        <label for="short">Sottotitolo</label>
                        <input disabled type="text" class="form-control" id="short" v-model="draftDetail.short">
                    </div>
                    <div class="form-group">
                        <label for="bodytext">Testo</label>
                        <div id="editor-container">
                            <ckeditor disabled ref="draftEditor" :editor="editor" v-model="draftDetail.bodytext" :config="draftEditorConfig"></ckeditor>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="short">Parole chiave</label>
                        <input disabled type="text" class="form-control" id="keywords" v-model="draftDetail.keywords">
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="title">Data</label>
                                <!-- input type="text" class="form-control" id="datetime" v-model="draftDetail.datetime" -->
                                <datepicker disabled v-model="draftDetail.datetime" id="datetime" locale="it" format="dd/MM/yyyy HH:mm"  />
                            </div>
                        </div>
                        
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="author">Autore</label>
                                <!-- input type="text" class="form-control" id="datetime" v-model="draftDetail.datetime" -->
                                <input disabled type="text" class="form-control" :value="getAuthor()" id="author" />
                            </div>
                        </div>
                        
                    </div>
                    
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="author">Data inizio</label>
                                <!-- input type="text" class="form-control" id="datetime" v-model="draftDetail.datetime" -->
                                <datepicker disabled v-model="draftDetail.starttime" :inputFormat="dateFormat" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="title">Data fine</label>
                                <!-- input type="text" class="form-control" id="datetime" v-model="draftDetail.datetime" -->
                                <datepicker disabled v-model="draftDetail.endtime" :inputFormat="dateFormat" />
                            </div>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-sm-6">
                            <label>Posizione</label>
                            <div class="form-group">
                                <div v-for="(position, index) in newsPaperStore.positions" :key="position.id">
                                    <div  class="icheck-primary d-inline">
                                        <input disabled type="checkbox" :id="position.id" :true-value="[]" v-model="draftDetail.positions" :value="position.id">
                                        <label :for="position.id">{{position.name}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <label></label>
                            <div class="form-group clearfix">
                                <div class="icheck-primary d-inline">
                                    <input disabled type="checkbox" id="premium" true-value="1" false-value="0" v-model="draftDetail.premium">
                                    <label for="premium">PREMIUM News</label>
                                </div>
                            </div>
            
                            <div class="form-group clearfix">
                                <div  class="icheck-primary d-inline">
                                    <input disabled type="checkbox"  id="hidden" true-value="1" false-value="0" v-model="draftDetail.hidden">
                                    <label for="hidden">Nascondi</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                    
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="author">Data inizio boxatura</label>
                                <!-- input type="text" class="form-control" id="datetime" v-model="draftDetail.datetime" -->
                                <datepicker disabled v-model="draftDetail.boxed_from" :inputFormat="dateFormat" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="title">Data fine boxatura</label>
                                <!-- input type="text" class="form-control" id="datetime" v-model="draftDetail.datetime" -->
                                <datepicker disabled v-model="draftDetail.boxed_to" :inputFormat="dateFormat" />
                            </div>
                        </div>
                    </div>
        
                    <div class="row h-100">
                        <div class="col-4">
                            <div class="form-group dam-image-list">  
                                <dam-images disabled :store="publishedStore" :media_file_path="'/draft-images'" @ondambrowse="onDamBrowse" />
                            </div>
                        </div>
                    </div>
                    <div class="mt-3 mb-2 row justify-content-end gx-5">
                        <div class="col-auto">
                            <button class="btn btn-secondary" @click="closePublished()">Chiudi</button>
                        </div>
                        <!-- Button trigger modal -->
                            <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#pubblicazioni">
                              Mostra pubblicazioni
                            </button>
                            
                            <!-- Modal -->
                            <div class="modal fade" id="pubblicazioni" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                              <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Elenco pubblicazioni</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    
                                    <template v-for="news in publishedStore.draftDetail.publishednews">
                                        <p class="mb-3" v-if="( newspaper = newsPaperStore.getNewspaperById(news.backend_server_id + '-' + news.newspaper_id) )"> <strong>{{ newspaper.name }}: </strong> {{ news.news_link }}</p>
                                    </template>
                               
                                  </div>
                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                     </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    data() {
        let editorConfig = enabledConfig;
        editorConfig["dam-image"]["click"] = () => { };
        return {
            // BEGIN: CKEditor5
            editor: ClassicEditor,
            editorData: "",
            draftEditorConfig: editorConfig,
            // END: CKEditor5
            showDamBrowser: false,
            showPublish: false,
            showPreview: false,
            dateFormat: "yyyy-MM-dd hh:mm",
            previewContent: "",
            showPublished: false,
        };
    },
    async mounted() {
        const route = useRoute();
        if (route.params.id) {
            await this.initNewsDetail(route.params.id);
        }
    },
    methods: {
        async initNewsDetail(draft_uuid) {
            const publishedStore = usePublishedStore();
            const loader = useLoading();
            loader.show();
            try {
                await publishedStore.fetchPublishedDetail(draft_uuid);
            }
            catch (err) {
                this.$notify({
                    text: "Errore nel caricamento della notizia",
                    type: "error"
                });
                console.log("Error loading published news", err);
                this.$router.push({ name: "draft-list-newspaper", params: { newspaperid: this.$router.currentRoute.value.params.newspaperid } });
            }
            loader.hide();
        },
        availableLang() {
            const newspaperStore = useNewsPaperStore();
            const publishedStore = usePublishedStore();
            const langs = newspaperStore.getNewspaperLangs(newspaperStore.selectedNewspaper);
            const langArray = Object.values(langs);
            // langArray: list of available languages on newspaper
            langArray.map((item, index) => {
                const lang = publishedStore.draftDetail.langInfo?.find((element) => {
                    return element.lang_id == item.uid;
                });
                if (lang) {
                    item.suffix = "";
                    item.draft_uuid = lang.draft_uuid;
                    item.published = lang.published;
                }
                else {
                    item.suffix = " [NEW]";
                    item.published = false;
                }
            });
            return langArray;
        },
        async onLangSelected(option) {
            if (option.draft_uuid) {
                // Open existing translation
                if (option.published) {
                    this.$router.push({ name: "news-view", params: { newspaperid: this.$router.currentRoute.value.params.newspaperid, uuid: option.draft_uuid } });
                    await this.initNewsDetail(option.draft_uuid);
                }
                else {
                    this.$router.push({ name: "draft-edit", params: { newspaperid: this.$router.currentRoute.value.params.newspaperid, uuid: option.draft_uuid } });
                }
            }
            else {
                // Make new translation
                const draftStore = useDraftStore();
                this.$router.push({ name: "draft-transl-new", params: { newspaperid: this.$router.currentRoute.value.params.newspaperid, uuid: this.$router.currentRoute.value.params.uuid, langid: option.uid } });
                const langInfo = draftStore.draftDetail.langInfo;
                draftStore.resetLocalStore();
                draftStore.draftDetail.langInfo = langInfo;
                draftStore.draftDetail.language_id = option.uid;
            }
        },
        getAuthor() {
            const publishedStore = usePublishedStore();
            return publishedStore.draftDetail.author;
        },
        async editPublished(news) {
            // Show Preview
            const publishedStore = usePublishedStore();
            const previewContent = await publishedStore.previewPublished(news);
            window.open(URL.createObjectURL(new Blob([previewContent], { type: "text/html" })));
        },
        onDamClosing() {
            this.showDamBrowser = false;
        },
        onDamBrowse() {
            this.showDamBrowser = true;
        },
        onShowPublished() {
            this.showPublished = true;
        },
        onPublishedClosgin() {
            this.showPublished = false;
        },
        async savePublished() {
            await this.publishedStore.saveAndClean();
            this.closePublished();
        },
        closePublished() {
            this.$router.push({ path: "/draft" });
        },
        onModalClose() {
            this.publishedStore.resetLocalStore();
        },
        publishedModal(action) {
            if (action == "close") {
                this.showPublish = false;
            }
        },
    },
    components: { }
}

</script>

<style scoped>
#publish-list {
    word-wrap: break-word;
}
#publish-list li {
    margin-bottom: 0px;
}
.box-positions {
    column-count: 2;
    margin: 10px 0;
}

.box-check {
    margin: 10px 0;
}
.box-light {
    border: 1px solid #d7d5d5;
    border-radius: 15px;
    padding: 0 15px;
    background-color: white;
}
</style>