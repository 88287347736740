<script setup>
import { ref } from 'vue';
import { useAuthStore, useBackendStore, useNewsPaperStore } from '../../stores';
import { useLoading } from '@/loader';
import { useNotification } from '@kyvg/vue3-notification';

const { notify } = useNotification();
const loader = useLoading();
const authStore = useAuthStore();


const user_profile_form = ref(null);

const password = ref("");
const password_confirm = ref("");

const newspapers = [];

const formErrors = ref([]);

const newsPaperStore = useNewsPaperStore();
const backendStore = useBackendStore();


const userData = ref(null);

authStore.me().then( (value) => {
    value.user.preferences = Object.assign(authStore.defaultPreferences, value.user.preferences);
    userData.value = value.user;
});



function saveForm() {

    if(!emptyPassword()) {
        if(checkPassword()) {
            userData.value.password = password.value;
        } else {
            // Password must match
            notify({
                    title: "Avviso",
                    text: "Le password devono coincidere",
                    type: 'danger',
            });
            return;
        }
    }
    // Save preferences

    loader.show();
    formErrors.value = [];
    authStore.updatePreferences(userData.value).then(value => {
        authStore.user.preferences = Object.assign(authStore.defaultPreferences, value.preferences);
        authStore.persist();
        notify( {
            title: "Salvataggio effettuato",
            text: "Preferenze aggiornate",
            type: 'success',
        });
    }).catch( reason => {
        if(reason.error?.validation?.fields) {
            for(const [key, value] of Object.entries(reason.error?.validation?.fields)) {
                console.log(key, value);
                formErrors.value[key] = value;
            }
            
        } else {
            notify({
                title: "Errore salvataggio",
                text: reason,
                type: 'danger',
            });
        }
    }).finally( () => {
        loader.hide();
    });
}

function emptyPassword() {
    return password.value == "" && password_confirm.value == "";
}

function checkPassword() {
    return password.value == password_confirm.value;
}

</script>

<template>
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>Profilo utente</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                        <li class="breadcrumb-item active">Profilo utente</li>
                    </ol>
                </div>
            </div>
        </div>
    </section>
    
    <section class="content">
        <div class="container-fluid">
            <form v-if="userData" ref="user_profile_form" name="user-profile" @submit.prevent="saveForm">
                <div class="card card-default">
                    <div class="card-header">
                        <h3 class="card-title"><i class="fas fa-user"></i> Dati utente</h3>
                    </div>
                    <div class="card-body">
                            <div class="form-group">
                                <label for="first_name">Nome</label>
                                <input disabled type="text" class="form-control" id="first_name" :value="userData.first_name">
                            </div>
                            <div class="form-group">
                                <label for="last_name">Cognome</label>
                                <input disabled type="text" class="form-control" id="last_name" :value="userData.last_name">
                            </div>
                            <div class="form-group">
                                <label for="username">Username</label>
                                <input disabled type="text" class="form-control" id="username" :value="userData.email">
                            </div>
                            <div class="form-group">
                                <label for="password">Password</label>
                                <input type="password" class="form-control" :class=" (formErrors.password?.length > 0) ? 'is-invalid' : '' " id="password" v-model="password">
                                <span class="error invalid-feedback" v-if="formErrors.password?.length > 0">
                                    La password deve essere lunga almeno 8 caratteri, contenere 1 numero, una maiuscola, una minuscola ed un carattere speciale
                                </span>
                            </div>
                            <div class="form-group">
                                <label for="password_confirm">Conferma Password</label>
                                <input type="password" class="form-control" id="password_confirm" v-model="password_confirm">
                            </div>
                            <button type="submit" class="btn btn-primary float-right">Salva</button>
                    </div>
                </div>
                
                <div class="card card-default">
                    <div class="card-header">
                        <h3 class="card-title"><i class="far fa-star"></i> Preferiti</h3>
                    </div>
                    <div class="card-body">
                            <div class="form-group">
                                <template v-for="(backend, backend_id) in newsPaperStore.newspaper">
                                    <strong>{{ backendStore.getBackendName(backend_id) }}</strong>
                                    <div class="mt-2 form-group">
                                        <div v-for="(newspaper, newspaper_id) in backend" >
                                            <div class="icheck-primary d-inline">
                                                <input  type="checkbox" class="form-check-input" :id="`${backend_id}-${newspaper.uid}`"  :value="`${backend_id}-${newspaper.uid}`" v-model="userData.preferences.newspapers" />
                                                <label class="form-check-label" :for="`${backend_id}-${newspaper.uid}`">{{ newspaper.name }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <button type="submit" class="btn btn-primary float-right">Salva</button>
                    </div>
                </div>

                <div class="card card-default">
                    <div class="card-header">
                        <h3 class="card-title"><i class="fas fa-tag"></i> Preferenze</h3>
                    </div>
                    <div class="card-body">
                        <div v-if="userData.preferences" class="form-group">
                            <div class="form-check">
                                <input type="checkbox" v-model="userData.preferences.hideArchiveTree" class="form-check-input" id="archive-tree">
                                <label class="form-check-label" for="archive-tree">Nascondi alberatura cartelle in Media</label>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary float-right">Salva</button>
                    </div>
                </div>
            
             </form>
        </div>
    </section>
        
</template>
