<script setup>

import { ref, onBeforeMount } from 'vue';
import { useRoute } from 'vue-router'
import { useUsersStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { fetchWrapper } from '../../_helpers/fetch-wrapper';
import { useNewsPaperStore } from '@/stores/newspaper.store';
import { useBackendStore } from '@/stores/backend.store';

const route = useRoute() 
const usersStore = useUsersStore();
const newsPaperStore = useNewsPaperStore();
const backendStore = useBackendStore();

usersStore.initUser();
if(route.params.id) {
    usersStore.getById(route.params.id).then( (response) => {
        getNewspapersForUser();
    });
}
const { user } = storeToRefs(usersStore);

const newspapers = ref([]);

function getNewspapersForUser() {
    newsPaperStore.getNewspapersForUser(user.value.id).then( (response) => {
        newspapers.value = response;
        for(const [backend_id, backend] of Object.entries(newspapers.value)) {
            if(!user.value.preferences.authors.hasOwnProperty(backend_id)) {
                user.value.preferences.authors[backend_id] = {};
            }
            for(const [newspaper_name, newspaper] of Object.entries(backend)) {
                if(!user.value.preferences.authors[newspaper.backendId].hasOwnProperty(newspaper.uid)) {
                    user.value.preferences.authors[newspaper.backendId][newspaper.uid] = null;
                }
            }
        }
    }).catch( (reason) => {

    });
}

onBeforeMount( () => {
    
});

</script>

<template>
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>Modifica utente</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                        <li class="breadcrumb-item active">Modifica utente</li>
                    </ol>
                </div>
            </div>
        </div>
    </section>
    
    <section class="content">
        <div class="container-fluid">
			<form ref="userform">
				<div class="card card-default">
					<div class="card-header">
                        <h3 class="card-title"><i class="fas fa-user"></i> Dati utente</h3>
                    </div>
                    <div class="card-body">
					
						<div class="form-group">
							<label for="first_name">Nome</label> 
							<input class="form-control" v-model="user.first_name" id="first_name">
						</div>
						
						<div class="form-group">
							<label for="first_name">Cognome</label> 
							<input class="form-control" v-model="user.last_name" id="last_name">
						</div>
						
						<div class="form-group">
							<label for="username">Username</label>
							<input class="form-control" v-model="user.email" id="username">
						</div>
						
						<div class="form-group">
							<label for="password">Password</label> 
							<input class="form-control" type="password" value="#notchanged#" id="password" ref="password">
						</div>

						<div class="form-group">
							<label for="roles">Ruoli</label> 
							<v-select id="roles" v-model="user.roles" :options="[{name: 'News Manager'}, {name:'User Manager'}]" label="name" :reduce="(el) => el.name" multiple></v-select>
						</div>
						
						<div class="form-group">
							<table class="table backend-server-list" v-if="backendServers.length">
								<tr v-for="backend_server in backendServers">
									<td>{{ backend_server.name}}</td>
									<td><v-select v-model="backend_server.user" :options="getBEUsers(backend_server.id)" label="username" :reduce="(el) => el.username" ></v-select></td>
								</tr>
							</table>
						</div>
						<button type="submit" class="btn btn-primary float-right" @click.prevent="onSave()">Salva</button>
					</div>
				</div>
					
				<div class="card card-default">
					<div class="card-header">
						<h3 class="card-title"><i class="far fa-star"></i> Preferiti</h3>
					</div>
					<div class="card-body">
						<div class="form-group">
							<template v-for="(backend, backend_id) in newspapers">
                                <h3>{{ backendStore.getBackendName(backend_id) }}</h3>
                                <table class="table newspaper-list">
                                    <thead>
                                        <tr>
                                            <th>Giornale preferito</th>
                                            <th>Autore predefinito</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(newspaper, newspaper_id) in backend">
                                            <td>
                                                <div class="mt-2 form-group">
                                                    <div class="icheck-primary d-inline">
                                                        <input  type="checkbox" class="form-check-input" :id="`${backend_id}-${newspaper.uid}`"  :value="`${backend_id}-${newspaper.uid}`" v-model="user.preferences.newspapers" />
                                                        <label class="form-check-label" :for="`${backend_id}-${newspaper.uid}`">{{ newspaper.name }}</label>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <v-select v-if="user.preferences.authors[backend_id]" v-model="user.preferences.authors[backend_id][newspaper.uid]" :options="newspaper.authors" label="label" :reduce="(el) => el.id" ></v-select>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

							</template>
						</div>
						<button type="submit" class="btn btn-primary float-right" @click.prevent="onSave()">Salva</button>
					</div>
				</div>

				<div class="card card-default">
					<div class="card-header">
						<h3 class="card-title"><i class="fas fa-tag"></i> Preferenze</h3>
					</div>
					<div class="card-body">
						<div v-if="user.preferences" class="form-group">
							<div class="form-check">
								<input type="checkbox" v-model="user.preferences.hideArchiveTree" class="form-check-input" id="archive-tree">
								<label class="form-check-label" for="archive-tree">Nascondi alberatura cartelle in Media</label>
							</div>
						</div>
						<button type="submit" class="btn btn-primary float-right" @click.prevent="onSave()">Salva</button>
					</div>
				</div>
					
			</form>
        </div>
    </section>
    
</template>

<script>

export default {
    data() {
        return {
            backendServers: [
            ],
        }
    },
    methods: {

        async onCancel() {
            this.$router.push({ path: '/admin/users'});
        },
        async onSave() {
            const usersStore = useUsersStore();

            let data = {
                first_name: usersStore.user.first_name,
                last_name: usersStore.user.last_name,
                email: usersStore.user.email,
                role: usersStore.user.roles,
                preferences: usersStore.user.preferences,
                backend_servers: [

                ]
            };
            for(var i = 0; i < this.backendServers.length; i++) {
                if(this.backendServers[i].user) {
                    data.backend_servers.push({
                        id: this.backendServers[i].id,
                        username: this.backendServers[i].user
                    });
                }
            }
            if(this.$refs.password.value != "#notchanged#") {
                data.password = this.$refs.password.value;
            }
            if(this.$router.currentRoute.value.params.id) {
                usersStore.update(this.$router.currentRoute.value.params.id, data).then( (value) => {
                    this.$notify({
                        title: 'Utente salvato',
                        type: 'success',
                    });
                    this.$router.push({ path: '/admin/users'});
                });
            } else {
                try {
                    const resp = await usersStore.register(data);
                    usersStore.user = {};
                    //usersStore.getAll();
                    this.$router.push({ path: '/admin/users'});
                } catch(e) {
                    let messages = [];
                    if(typeof e == 'object') {
                        messages = Object.keys(e).reduce(function(r, k) {
                            r.push(e[k]);
                            return r;
                        }, []);
                    }
                    this.$notify({
                        title: 'Error',
                        text: messages.join("<br>"),
                        type: 'danger'
                    });
                }
            }

        },
        getBEUsers(backend_id) {
            let users = [];
            this.backendServers.forEach((server) => {
                if(server['id'] == backend_id) {
                    if(server['users']) {
                        users = Object.keys(server['users']).reduce(function (r, k) {
                        return r.concat(server['users'][k]);
                    }, []);
                    }
                }
            });
            return users;
        }
    },
    async mounted() {
        // Retrieve servers
        const baseUrl = `${import.meta.env.VITE_API_URL}/admin`;
        const usersStore = useUsersStore();

        let resp = await fetchWrapper.get(`${baseUrl}/servers`);

        for(var i = 0; i < resp.servers.length; i++) {
            for(var l = 0; l < usersStore.user.backend_servers?.length; l++) {
                if(usersStore.user.backend_servers[l].id == resp.servers[i].id) {
                    resp.servers[i].user = usersStore.user.backend_servers[l].pivot.typo3_username;
                }
            }
        }
        this.backendServers = resp.servers;
    }
}
</script>
