<script setup>
import { getCurrentInstance, watch } from 'vue'
import { useAuthStore } from '../../stores';

const authStore = useAuthStore();


watch(() => authStore.user.preferences.hideArchiveTree, (hideArchiveTree) => {
    const instance = getCurrentInstance();
    instance.proxy.forceUpdate();
});
</script>


<template>
    <li v-if="filemount" :class="(isSelected(filemount.id) ? 'filemount-item selected' : 'filemount-item')" >
        <i v-if="filemount.root?.length > 0 && !authStore.user.preferences.hideArchiveTree" :class="(isOpening || isClosing ? 'fas fa-spinner fa-spin' : (filemount.opened ? 'far fa-folder-open mr-1' : 'far fa-folder-closed mr-1'))"  @click="toggleStatus(filemount)"></i>
        <i v-else class="far fa-folder mr-1"></i>
		<span class=" filemount" @click="folderSelect(filemount.id)">{{ filemount.name }}</span>
        <template v-if="(filemount.opened) && !authStore.user.preferences.hideArchiveTree">
            <Lazy><FolderTree ref="tree" @beforemount="childBeforeMount()" @mounted="childMounted(filemount)" :filemount_id="filemount.id" :baseUrl="filemount.base_url" :parentPath="filemount.id" :folders="filemount.root" :level=1 :opened=filemount.opened @folderselect="folderSelect" @opening="(status) => childOpening(status)" /></Lazy>
        </template>
    </li>
</template>

<script>

import { useFilemountStore } from '../../stores/filemount.store';
import Lazy from '../Lazy.vue';
import FolderTree from './FolderTree.vue';

export default {
    components: {
    FolderTree,
    Lazy
},
    data() {
        return {
            isOpening: false,
            isClosing: false,
            childMounting: 0,
        }
    },
    emits: [
        "folderselect",
        "opening"
    ],
    props: {
        'filemount': Object
    },
    computed: {
        icon() {
            let icon_class = '';
            if(this.isOpening) {
                icon_class = 'fas fa-spinner fa-spin';
            } else {
                if(this.filemount.opened) {
                    icon_class = 'fas fa-chevron-down';
                } else {
                    icon_class = 'fas fa-chevron-right';
                }
            }
            return icon_class;
        },
    },
    methods: {
        childOpening(status) {
            this.$emit("opening", status);
        },
        async childBeforeMount() {
            this.childMounting++;
        },
        async childMounted() {
            this.childMounting--;
            if(this.childMounting == 0) {
                this.isOpening = false;
                this.$emit("opening", this.isOpening);
            }
        },
        toggleStatus() {
            if(!this.filemount.opened) {
                this.isOpening = true;
                this.$emit("opening", this.isOpening);
            } else {
                //this.isClosing = true;
            }
            this.filemount.opened = !this.filemount.opened;
        },
        isSelected(id) {
            const filemountStore = useFilemountStore();
            return id == filemountStore.lastPath;
        },
        async folderSelect(path) {
          // From CategoryTree
          const filemountStore = useFilemountStore();
          if(path != filemountStore.lastPath) {
            this.$emit("folderselect", path);        
          }
        },
    }
}
</script>