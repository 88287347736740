<script setup>
import { ref } from 'vue';
import draggable from 'vuedraggable'
import { useDraftStore } from '@/stores';

const props = defineProps(['modelValue', 'disabled']);
const emit = defineEmits(['update:modelValue', 'onrelatedbrowse']);

const store = useDraftStore();
// Internal state
const deleted = ref([]);
const showDraftBrowser = ref(false);
const searchTerm = ref("");

function canMove() {
    return !props.disabled;
}

function draftBrowser() {
    if(showDraftBrowser) {
        //this.$emit('ondambrowse', ondambrowse);
        showDraftBrowser.value = false;
    }
    showDraftBrowser.value = true;
    emit('onrelatedbrowse', showDraftBrowser);
}

function getDraftTitle(draft) {
    return draft.title;
}

function add(draft) {
    if( !props.modelValue.some( (item) => {
        item.uuid == draft.uuid
    }) ) {
        props.modelValue.add(draft);
        emit('update:modelValue', props.modelValue);
    }
}

function remove(draft) {
    const modelValue = props.modelValue.filter(function(item) {
        return item.id != draft.id;
    });
    //deleted.add(draft.id);
    emit('update:modelValue', modelValue);
}

function onUpdateModelValue(event) {
    emit('update:modelValue', event);
}
</script>

<template>
    <div class="card">
		<div class="card-body">
			<div v-if="!disabled" class="btn btn-default btn-sm mb-3">
				<i class="fas fa-folder-open" @click="draftBrowser" title="Sfoglia dall'archivio news"></i>
			</div>
			<draggable 
				:move="canMove"
				:modelValue="modelValue"
				@update:modelValue="onUpdateModelValue" 
				group="related-drafts"
				item-key="uuid" class="scrollable-box">
				<template #item="{ element }">
					<div :data-id="element.sorting" class="draft-container draft-margin">
						<div>{{ getDraftTitle(element) }}</div>
						<button v-if="!disabled" @click="remove(element)" class="close-btn" type="button">
							<svg 
								class='times-icon' 
								xmlns="http://www.w3.org/2000/svg" 
								width="0.65em" 
								height="0.65em" 
								preserveAspectRatio="xMidYMid meet" 
								viewBox="0 0 352 512">
									<path 
										d="m242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28L75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256L9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
										fill="currentColor"    
									/>
							</svg>
						</button>
					</div>
				</template>
			</draggable>
		</div>
		<!-- div v-for="(file, index) in store.draftDetailRelated.deleted_media" :key="index" class="m-top">
			<input type="text" name="deleted_media[]" :value="file.name" hidden>
		</div -->
	</div>
</template>

<style>
.draft-container {
    position: relative;
	width: 100%;
    padding: 10px;
	margin-bottom: 0.5rem !important;
	margin-right: 0.3rem !important;
	background: #EEE;
    cursor: move !important;
}
.close-btn{
    background: none !important;
	color:red !important;
	border: none !important;
	padding: 0px !important;
    margin:0px !important;
	font: inherit !important;
	cursor: pointer !important;
	outline: inherit !important;
    position: absolute !important;
    right: 25px !important;
    top: 0 !important;
    width: 0px !important;
}
.times-icon{
    font-size: 2rem !important;
    padding: 0px !important;
    margin:0px !important;
}
</style>