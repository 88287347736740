<script setup>
import { ref, reactive, onMounted, computed } from 'vue'
import { useDragAndDrop } from "@formkit/drag-and-drop/vue";
import { multiDrag, selections } from "@formkit/drag-and-drop";


// Props
const props = defineProps({
    store: {
        type: Object
    },
    media_server: {
        type: String
    },
    error: '',
    server: {
        type: String,
        default: '/api/upload',
    },
    media_file_path:{
        type: String,
    },
    disabled: {
        type: Boolean,
        default: false,
    }
});

// Emits
const emit = defineEmits([
    'added-media',
    'deleted-media',
    'saved-media',
    'ondambrowse'
]);


// Data
const loading = ref(true)
const showDamBrowser = ref(false)
const forbiddenExtensions = reactive([
    'php',
    'php3',
    'exe',
    'com',
    'bat',
]);

const modal_browser = ref(null);

onMounted( () => {
    $(modal_browser).on("hide.bs.modal", closeBrowser);
});

// Methods
const canMove = (evt, originalEvent) => {
    if(props.disabled) return false
    return true;
}

async function damBrowser() {
    if(showDamBrowser) {
        showDamBrowser.value = false;
    }
    showDamBrowser.value = true;
    emit('ondambrowse', showDamBrowser);
}

async function fileChange(event) {
    loading.value = true
    let files = event.target.files
    for(var i=0; i < files.length; i++){
        var re = /(?:\.([^.]+))?$/;
        var extension = re.exec(files[i].name);
        if( forbiddenExtensions.indexOf(extension[1]) == -1 ) {
            //let formData = new FormData
            let url = URL.createObjectURL(files[i])
            //formData.set('image', files[i])
            //const {data} = await axios.post(this.server, formData)
            //props.store.draftDetailImages.added_media.push(files[i]);
            props.store.draftDetailFiles.files.push({url:url, name:files[i].name, size:files[i].size, type:files[i].type, orig:files[i], caption:''});
        } else {
            this.$notify({
                'type': 'error',
                'title': 'Errore caricamento',
                'text': 'Estensione file non permessa: <strong>' + extension[1] + '</strong>'
            });
        }
    }
    loading.value = false
    media_emit()
}

function getMediaUrl(media) {
    let url;
    if(media.is_remote) {
        url = media.path; 
    } else if(media.path) {
        url = props.media_file_path +'/'+ media.path;
    } else if(media.orig) {
        url = media.url;
    } else {
        // New remote added image: prepend backend public URL
        url = media.publicUrl + media.url;
    }
    return url;
}

function remove(element) {
    if(element.id) {
        props.store.draftDetailFiles.deleted_media.push(element);
    }
    props.store.draftDetailFiles.files.splice(props.store.draftDetailFiles.files.indexOf(element),1)
    media_emit()
}

function closeBrowser() {
    showDamBrowser.value = false;
}

function getMediaName(media) {
    if(media.type == "remote") {
        return media.file_name;
    } else if(media.file_name) {
        return media.file_name;
    }
    return media.name;
}

function media_emit() {
    emit('added-media', props.store.draftDetailFiles.added_media)
    emit('deleted-media', props.store.draftDetailFiles.deleted_media)
    emit('saved-media', props.store.draftDetailFiles.saved_media)
}


const filesData = computed({
    get() {
        return props.store.draftDetailFiles.files ?? [];
    },
    set(newValue) {
        props.store.draftDetailFiles.files = newValue;
    }  
});

const [ dragFilesEl, files] = useDragAndDrop(filesData, {
    group: "files",
    plugins: [
        multiDrag({
            plugins: [
                selections({
                    selectedClass: "sortable-selected",
                }),
            ],
        }),
    ],
});

</script>

<template>
    <div>
        <div class="form-group">
            <div class="card" :class="error?'red-border':''">
                <div class="card-body">
                    
                    <!--UPLOAD BUTTON-->
                    <!--
                    <div v-if="!disabled" class="button-container image-margin">
                        <label for="files-upload" class="files-upload">
                            <svg
                                class="custom-icon"
                                xmlns="http://www.w3.org/2000/svg" 
                                width="1em" 
                                height="1em" 
                                preserveAspectRatio="xMidYMid meet" 
                                viewBox="0 0 24 24">
                                    <g fill="none">
                                        <path 
                                            d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11s11-4.925 11-11S18.075 1 12 1zm1 15a1 1 0 1 1-2 0v-3H8a1 1 0 1 1 0-2h3V8a1 1 0 1 1 2 0v3h3a1 1 0 1 1 0 2h-3v3z" 
                                            fill="currentColor"/>
                                    </g>
                            </svg>
                        </label>     
                        <input @change="fileChange" id="files-upload" type="file" accept="*/*" multiple hidden>
                    </div>
                    -->
                    
					<div class="btn-group mb-3">
						<div v-if="!disabled" class="btn btn-default btn-sm">
							<i class="fas fa-folder-open" @click="damBrowser" title="Sfoglia dall'archivio media"></i>
						</div>
						
						<div v-if="!disabled" class="btn btn-default btn-sm">
							<label for="files-upload" style="margin:0;">
								<i class="fas fa-file-upload" title="Carica nuovo"></i>
							</label>     
							<input @change="fileChange" id="files-upload" type="file" multiple hidden>
						</div>
					</div>

                    <!--IMAGES PREVIEW-->
                    <div ref="dragFilesEl" class="scrollable-box">
                        <div v-for="element in files" :key="element" class="file-container">
                            <div>{{ getMediaName(element) }}</div>
                            <button v-if="!disabled" @click="remove(element)" class="close-btn" type="button">
                                <svg 
                                    class='times-icon' 
                                    xmlns="http://www.w3.org/2000/svg" 
                                    width="0.65em" 
                                    height="0.65em" 
                                    preserveAspectRatio="xMidYMid meet" 
                                    viewBox="0 0 352 512">
                                        <path 
                                            d="m242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28L75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256L9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                                            fill="currentColor"    
                                        />
                                </svg>
                            </button>
                            <div class="selected-overlay"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if='error' id="media-required">
            <p class='red-text'>{{error}}</p>
        </div>
        <div v-for="(file, index) in store.draftDetailFiles.deleted_media" :key="index" class="m-top">
            <input type="text" name="deleted_media[]" :value="file.name" hidden>
        </div>
    </div>
</template>

<style scoped>
.grow-wrap {
  /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
  display: grid;
  position: absolute;
  bottom: 0;
  font-size: .6rem;
  width: 100%;
  z-index:1;
}
.grow-wrap::after {
  /* Note the weird space! Needed to preventy jumpy behavior */
  content: attr(data-replicated-value) " ";

  /* This is how textarea text behaves */
  white-space: pre-wrap;

  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
}
.grow-wrap > textarea {
  /* You could leave this, but after a user resizes, then it ruins the auto sizing */
  resize: none;

  /* Firefox shows scrollbar on growth, you can hide like this. */
  overflow: hidden;
}
.grow-wrap > textarea,
.grow-wrap::after {
  /* Identical styling required!! */
  border: 1px solid black;
  /* padding: 0.5rem; */
  font: inherit;

  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
}
.scrollable-box {
    display:flex;
    flex-wrap:wrap;
    max-height:390px;
    width:100%;
    overflow-y:auto;
}
.image-wraper{
    min-height: 200px !important;
}

.files-upload {
    background-color: #ffffff !important;
    border-radius: 5px !important;
    border: 1px dashed #ccc !important;
    display: inline-block !important;
    cursor: pointer !important;
    width: 165px !important;
    height: 88px !important;
}
.files-upload:hover{
    background-color: #f1f1f1 !important;
}

.file-container {
    position: relative;
	width: 100%;
    padding: 10px;
	margin-bottom: 0.5rem !important;
	margin-right: 0.3rem !important;
	background: #EEE;
    cursor: move !important;
}

.file-container .selected-overlay {
    display:none;
    background-color: rgba(0, 123, 255, 0.1);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.file-container.sortable-selected .selected-overlay {
    display: inline-block;
}

.button-container{
    display: inline-flex !important;
    height: 90px !important;
    width: 140px !important;
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
}

.close-btn{
    background: none !important;
	color:red !important;
	border: none !important;
	padding: 0px !important;
    margin:0px !important;
	font: inherit !important;
	cursor: pointer !important;
	outline: inherit !important;
    position: absolute !important;
    right: 25px !important;
    top: 0 !important;
    width: 0px !important;
	z-index:1;
}
.times-icon{
    font-size: 2rem !important;
    padding: 0px !important;
    margin:0px !important;
}
.custom-icon{
    color: #007bff !important;
    font-size: 3rem !important;
    margin-top: 18px !important;
    margin-left: 44px !important;
    
}

.close-btn:hover{
    color: rgb(190, 39, 39) !important;
}


/* -------------------- */


.red-border {
    border: 1px solid #dc3545 !important;
    border-color: #dc3545 !important;
}
.red-text {
    color: #d82335;
}

</style>
