<script setup>

import { useRoute } from 'vue-router'
import { watch } from 'vue'
import { useDraftStore, useNewsPaperStore } from '@/stores';
import { storeToRefs } from 'pinia';
import DamImageBrowser from '../../components/newspaper/DamImageBrowser.vue';
import RelatedBrowser from '@/components/newspaper/RelatedBrowser.vue';
import RelatedDraft from '@/components/newspaper/RelatedDraft.vue';
import DamImages from '../../components/newspaper/DamImages.vue';
import DamFiles from '../../components/newspaper/DamFiles.vue';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import PublishDraft from '../../components/newspaper/PublishDraft.vue';

import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
import enabledConfig from '@/ckeditor5/config/enabled';
import { useLoading } from '@/loader';
import RelatedRemoteBrowser from '@/components/newspaper/RelatedRemoteBrowser.vue';
const draftStore = useDraftStore();
const newsPaperStore = useNewsPaperStore();
const { isDraftLoading, draftList, draftDetail, draftDetailImages } = storeToRefs(draftStore);
const { selectedNewspaper } = storeToRefs(newsPaperStore);


watch( () => draftStore.mustSaveDraft, (value) => {
    if(value) {
        draftStore.saveDraft().then( (res) => {
            draftStore.mustSaveDraft = false;
            draftStore.startUpload = true;
        });
    }
});

</script>

<template>
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Bozze</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
          <li class="breadcrumb-item active">Bozze</li>
        </ol>
      </div>
    </div>
  </div>
</section>


<section class="content">
    <div class="container-fluid">
        <div class="card card-default">
            <form name="draft-edit" class="m-3">

                <div v-if="availableLang().length > 1" class="form-group">
                    <label for="lang">Lingua</label>
                    <v-select :modelValue="draftStore.draftDetail.language_id" :options="availableLang()" @option:selected="(option) => onLangSelected(option)" label="title" :reduce="(el) => el.uid" :clearable="false" >
                        <template #option="{title, suffix}">
                            {{ title }}{{ suffix }}
                        </template>
                        <template #selected-option="{title, suffix}">
                            {{ title }}{{ suffix }}
                        </template>
                    </v-select>
                </div>
                <div class="form-group">
                    <label class="control-label" for="title">Titolo</label>
                    <input required type="text" class="form-control" id="title" v-model="draftDetail.title" @keydown.enter="(e) => e.preventDefault()">
                </div>
                <div class="form-group">
                    <label for="short">Sottotitolo</label>
                    <input type="text" class="form-control" id="short" v-model="draftDetail.short" @keydown.enter="(e) => e.preventDefault()">
                </div>
                <div class="form-group">
                    <label>Testo</label>
                    <div id="editor-container">
                        <ckeditor ref="draftEditor" :editor="editor" v-model="editorData" :config="draftEditorConfig" @ready="onEditorReady()"></ckeditor>
                    </div>
                </div>
                <div class="form-group">
                    <label for="short">Parole chiave <small>(separate da ,)</small></label>
                    <input type="text" class="form-control" id="keywords" v-model="draftDetail.keywords" @keydown.enter="(e) => e.preventDefault()">
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="title">Data</label>
                            <datepicker required v-model="draftDetail.datetime" :text-input="textInputOptions" :disabled="!enabledDate" id="datetime" locale="it" format="dd/MM/yyyy HH:mm" auto-apply :close-on-auto-apply="false" partial-flow :flow="['calendar', 'time']" />
                        </div>
                    </div>
                    
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="author">Autore</label>
                            <input type="text" class="form-control" v-model="draftDetail.author" id="author" @keydown.enter="(e) => e.preventDefault()" />
                        </div>
                    </div>
                    
                </div>
                
                <div class="row">
                    <div class="col-md-6">
                    <div class="form-group">
                        <label for="title">Data inizio</label>
                        <!-- input type="text" class="form-control" id="starttime" v-model="draftDetail.starttime" -->
                        <datepicker v-model="draftDetail.starttime" :inputFormat="dateFormat" :text-input="textInputOptions"  locale="it" format="dd/MM/yyyy HH:mm" auto-apply :close-on-auto-apply="false" partial-flow :flow="['calendar', 'time']"  @update:model-value="onStartTimeChange" />
                    </div>
                    </div>
                    <div class="col-md-6">
                    <div class="form-group">
                        <label for="title">Data fine</label>
                        <!-- input type="text" class="form-control" id="endtime" v-model="draftDetail.endtime" -->
                        <datepicker v-model="draftDetail.endtime" :inputFormat="dateFormat" :text-input="textInputOptions"  locale="it" format="dd/MM/yyyy HH:mm" auto-apply :close-on-auto-apply="false" partial-flow :flow="['calendar', 'time']"  />
                    </div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-sm-6">
                        <label>Posizione</label>
                        <div class="form-group">
                        <div v-for="(position, index) in newsPaperStore.positions" :key="position.id">
                            <div class="icheck-primary d-inline">
                                <input type="checkbox" :id="position.id" :true-value="[]" v-model="draftDetail.positions" :value="position.id">
                                <label :for="position.id">{{position.name}}</label>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label></label>
                        <div class="form-group clearfix">
                            <div  class="icheck-primary d-inline">
                                <input type="checkbox" id="premium" true-value="1" false-value="0" v-model="draftDetail.premium">
                                <label for="premium">PREMIUM News</label>
                            </div>
                        </div>

                        <div class="form-group clearfix">
                            <div  class="icheck-primary d-inline">
                                <input type="checkbox"  id="hidden" true-value="1" false-value="0" v-model="draftDetail.hidden">
                                <label for="hidden">Nascondi</label>
                            </div>
                        </div>
                    </div>
                </div>
                
                
                <div class="row">
                    <div class="col-md-6">
                    <div class="form-group">
                        <label for="title">Data inizio boxatura</label>
                        <datepicker v-model="draftDetail.boxed_from" :inputFormat="dateFormat" :text-input="textInputOptions"  locale="it" format="dd/MM/yyyy HH:mm" auto-apply :close-on-auto-apply="false" partial-flow :flow="['calendar', 'time']" />
                    </div>
                    </div>
                    <div class="col-md-6">
                    <div class="form-group">
                        <label for="title">Data fine boxatura</label>
                        <datepicker v-model="draftDetail.boxed_to" :inputFormat="dateFormat" :text-input="textInputOptions" locale="it" format="dd/MM/yyyy HH:mm" auto-apply :close-on-auto-apply="false" partial-flow :flow="['calendar', 'time']"  />
                    </div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-md-6">
                        <label>Immagini</label>
                        <dam-images :store="draftStore" :media_file_path="'/draft-images'" @ondambrowse="onDamBrowse(true, 'attach')" />
                        
                    </div>
                    <div class="col-md-6">
                        <label>Allegati</label>
                        <dam-files :store="draftStore" :media_file_path="'/draft-files'" @ondambrowse="onDamBrowse(true, 'file')" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 ">
                        <label>Notizie correlate</label>
                        <RelatedDraft  @onrelatedbrowse="onRelatedBrowse(true)" v-model="draftDetail.related" />
                    </div>
                </div>
                <div class="mt-3 mb-2 row justify-content-end gx-5">
                    <div class="col-auto mb-1">
                        <div class="btn btn-secondary" @click="closeDraft()">Chiudi</div>
                    </div>
                    <div class="col-auto mb-1">
                        <div class="btn btn-secondary" @click="saveDraft()">Salva</div>
                    </div>
                    <div class="col-auto mb-1">
                        <div class="btn btn-secondary" @click="saveDraftAndClose()">Salva e Chiudi</div>
                    </div>
                    <div class="col-auto">
                        <div class="btn btn-primary" @click="publishDraft()">Salva e Pubblica</div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
<div class="modal fade" ref="damBrowser"  tabindex="-1" role="dialog" data-keyboard="false" >
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                 <h4 class="modal-title">Media</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
                </button>
            </div>
    
            <div class="modal-body">
                <DamImageBrowser ref="modal_browser" :show="showDamBrowser" :store="draftStore" @addimage="onAddImage" @hidebsmodal="onDamClose" :context="damContext" />
            </div>
        </div>
    </div>
</div>

<div class="modal fade" ref="relatedBrowser" tabindex="-1" role="dialog" data-keyboard="false">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Notizie</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
                </button>
            </div>

            <div class="modal-body">
                <!-- RelatedBrowser ref="modal_related_browser" :show="showRelatedBrowser" :language="draftStore.draftDetail.language_id" :draft_uuid="draftStore.draftDetail.uuid" @hidebsmodal="onRelatedClose" @addrelated="onAddRelated" / -->
                <RelatedRemoteBrowser ref="modal_related_browser" :show="showRelatedBrowser" :language="draftStore.draftDetail.language_id" :remote_id="0" @hidebsmodal="onRelatedClose" @addrelated="onAddRelated" />
            </div>
        </div>
    </div>
</div>
    <!-- BEGIN: Modal Publish -->
    <publish-draft id="modal_publish" ref="modal_publish" :show="showPublish" @modal-action="draftModal" />
    <!-- BEGIN: Modal Publish -->

</template>

<script>

export default {
    data() {
        let editorConfig = enabledConfig;
        editorConfig['dam-image']['click'] = this.onDamBrowse;
        return {
            // BEGIN: CKEditor5
            editor: ClassicEditor,
            editorData: '',
            
            draftEditorConfig: editorConfig,
            // END: CKEditor5
            showDamBrowser: false,
            showRelatedBrowser: false,
            showPublish: false,
            dateFormat: "yyyy-MM-dd hh:mm",
            textInputOptions: {
                format: "dd/MM/yyyy HH:mm"
            },
            damContext: "attach",
            visibleVideoInsert: false,
            editorContent: '',
            enabledDate: true,
        };
    },
    async mounted() {
        this.modalDamBrowser = new bootstrap.Modal(this.$refs.damBrowser);
        this.modalDamBrowser._config.keyboard = false;
        $(this.modalDamBrowser._element).on('hide.bs.modal', this.onDamClosing);

        this.modalRelatedBrowser = new bootstrap.Modal(this.$refs.relatedBrowser);
        this.modalRelatedBrowser._config.keyboard = false;
        $(this.modalRelatedBrowser._element).on('hide.bs.modal', this.onRelatedClosing)
        await this.initNewsDetail();
        const draftStore = useDraftStore();
        if(draftStore.draftDetail.starttime != null) {
            this.enabledDate = false;
        }


    },
    methods: {
        onStartTimeChange(value) {
            const draftStore = useDraftStore();
            if(value) {
                draftStore.draftDetail.datetime = value;
                this.enabledDate = false;
            } else {
                this.enabledDate = true;
                draftStore.draftDetail.datetime = null;
            }
        },
        isDefaultLangPublished() {
            const draftStore = useDraftStore();
            if(draftStore.draftDetail.language_id == 0) {
                return true;
            }
            const defaultLang = this.availableLang().reduce((cum, curr) => {
                if(curr.lang_id == 0) {
                    cum = curr;
                }
                return cum;
            });
            return defaultLang.published;
        },
        async initNewsDetail(draft_uuid) {
            const route = useRoute();
            const draftStore = useDraftStore();
            
            if(!draft_uuid && this.$router.currentRoute.value.params.uuid) {
                draft_uuid = this.$router.currentRoute.value.params.uuid;
            }
            if (draft_uuid) {
                try {
                    const loader = useLoading();
                    loader.show();
                    if(draftStore.draftDetail.uid != draft_uuid) {
                        await draftStore.fetchDraftDetail(draft_uuid);
                    }
                    this.editorData = draftStore.draftDetail.bodytext;
                    this.$refs.draftEditor.instance.draftId = draftStore.draftDetail.uuid;
                    loader.hide();
                }
                catch (err) {
                    this.$notify({
                        text: "Errore nel caricamento della notizia",
                        type: "danger"
                    });
                    console.log("Error loading draft", err);
                    this.$router.push({ name: "draft-list-newspaper", params: { newspaperid: this.$router.currentRoute.value.params.newspaperid } });
                }
            } else {
                draftStore.resetLocalStore();
            }

            if(this.$router.currentRoute.value.name == "draft-transl-new") {
                const langInfo = draftStore.draftDetail.langInfo;
                draftStore.resetLocalStore();
                draftStore.draftDetail.langInfo = langInfo;
            }
        },
        async onLangSelected(option) {
            debugger;
            if(option.draft_uuid) {
                // Open existing translation
                if(option.published) {
                    this.$router.push({ name: 'news-view', params: { newspaperid: this.$router.currentRoute.value.params.newspaperid, uuid: option.draft_uuid } });
                } else {
                    this.$router.push({ name: "draft-edit", params: { newspaperid: this.$router.currentRoute.value.params.newspaperid, uuid: option.draft_uuid } });
                    await this.initNewsDetail(option.draft_uuid);
                }
            } else {
                // Make new translation
                const draftStore = useDraftStore();
                this.$router.push({ name: "draft-transl-new", params: { newspaperid: this.$router.currentRoute.value.params.newspaperid, uuid: this.$router.currentRoute.value.params.uuid, langid: option.uid } });
                const langInfo = draftStore.draftDetail.langInfo;
                draftStore.resetLocalStore();
                draftStore.draftDetail.langInfo = langInfo;
                draftStore.draftDetail.language_id = option.uid;
            }
        },
        availableLang() {
            const newspaperStore = useNewsPaperStore();
            const draftStore = useDraftStore();
            const langs = newspaperStore.getNewspaperLangs(newspaperStore.selectedNewspaper);
            const langArray = Object.values(langs);
            //console.log("Newspaper langs", langArray);
            // langArray: list of available languages on newspaper
            langArray.map((item, index) => {
                const lang = draftStore.draftDetail.langInfo?.find((element) => {
                    return element.lang_id == item.uid
                });

                if( lang ) {
                    item.suffix = "";
                    item.draft_uuid = lang.draft_uuid;
                    item.published = lang.published;
                } else {
                    item.suffix = " [NEW]";
                    item.published = false;
                }
            });
            return langArray;
        },
        onEditorReady(evt) {
            const draftStore = useDraftStore();
            this.$refs.draftEditor.instance.model.on('_afterChanges', evt => {
                FB.XFBML.parse();
            });
            this.$refs.draftEditor.instance.saveDraft = this.saveDraft;
            this.$refs.draftEditor.instance.uploadImage = draftStore.uploadImage;
        },
        onSlideVideoClosing(event) {
            this.visibleVideoInsert = false;
        },
        onAddImage(image) {
            this.$refs.draftEditor.instance.execute('insertImage', { source: image.publicUrl+ "/" + image.url + "?uid=" + image.remote_id });
            let lastRange = this.$refs.draftEditor.instance.ui.editor.model.document.selection.getLastRange();
            this.$refs.draftEditor.instance.model.change( writer => {
                writer.setSelection( writer.createPositionAfter(  this.$refs.draftEditor.instance.ui.editor.model.document.selection.getSelectedElement()) );
            } );

        },
        onAddRelated(draft) {
            const draftStore = useDraftStore();
            const found = draftStore.draftDetail.related.find((item) => {
                if(draft.uid) return item.uid == draft.uid;
                return item.uuid == draft.uuid;
            });

            if(!found) {
                draftStore.draftDetail.related.push(draft);
            }
        },
        onDamClose() {
            this.modalDamBrowser.hide();
        },
        onRelatedClose() {
            this.modalRelatedBrowser.hide();
        },
        onDamClosing() {
            this.showDamBrowser = false;
        },
        onRelatedClosing() {
            this.showRelatedBrowser = false;
        },
        onDamBrowse(show, context = "attach") {
            this.modalDamBrowser.show();
            this.showDamBrowser = true;
            this.damContext = context;
        },
        onRelatedBrowse(show) {
            this.modalRelatedBrowser.show();
            this.showRelatedBrowser = true;
        },
        async publishDraft() {
            const draftStore = useDraftStore();
            draftStore.draftDetail.bodytext = this.editorData;
            if (document.getElementsByName("draft-edit")[0].checkValidity()) {
                const loader = useLoading();
                try {
                    loader.show();
                    await draftStore.saveDraft();
                    //this.modalDraft.hide();
                    this.showPublish = true;
                } catch(e) {
                    this.$notify({
                        text: "Errore nel salvataggio della notizia",
                        type: "danger"
                    });
                } finally {
                    loader.hide();
                }
            } else {
                document.getElementsByName("draft-edit")[0].reportValidity();
            }
        },
        saveDraft() {
            return new Promise((resolve, reject) => {
                if (document.getElementsByName("draft-edit")[0].checkValidity()) {
                    try {
                        const loader = useLoading();
                        loader.show();
                        const draftStore = useDraftStore();
                        draftStore.draftDetail.bodytext = this.editorData;
                        draftStore.saveDraft().then( () => {
                            this.$notify({
                                text: "Notizia salvata",
                                type: "success"
                            });
                            //draftStore.fetchDraftNewspaper(this.$router.currentRoute.value.params.newspaperid);
                            resolve();
                        }).catch((reason) => {
                            this.$notify({
                                text: "Errore nel salvataggio della notizia",
                                type: "danger"
                            });
                        }).finally(() => {
                            loader.hide();
                        });
                    } catch(e) {
                        this.$notify({
                            text: e.message,
                            type: "danger"
                        });
                        reject(e.message);
                    }
                } else {
                    document.getElementsByName("draft-edit")[0].reportValidity();
                    reject("Form not valid");
                }
            });
        },
        async saveDraftAndClose() {
            this.saveDraft().then(() => {
                const draftStore = useDraftStore();
                draftStore.resetLocalStore();
                this.closeDraft();
            }).catch((reason) => {
                console.log("Error saving draft", reason);
            });
        },
        closeDraft() {
            this.$router.push({ name: "draft-list-newspaper", params: { newspaperid: this.$router.currentRoute.value.params.newspaperid } });
        },
        onModalClose() {
            const draftStore = useDraftStore();
            draftStore.resetLocalStore();
        },
        draftModal(action) {
            if (action == "close") {
                this.showPublish = false;
            } else if(action == "done") {
                this.showPublish = false;
                this.closeDraft();
            }
        },
    },
    components: {  }
}

</script>

<style scoped>
.box-positions {
    column-count: 2;
    margin: 10px 0;
}

.box-check {
    margin: 10px 0;
}
.box-light {
    border: 1px solid #d7d5d5;
    border-radius: 15px;
    padding: 0 15px;
    background-color: white;
}
</style>
