import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

export class RteImage extends Plugin {
    init() {
        const editor = this.editor;
        editor.model.schema.extend( 'imageInline', { allowAttributes: ['txdam'] } );

        //console.log("Newspaper_id", route.params.newspaperid);
        editor.conversion.for( 'upcast' ).elementToElement( {
            view: {
                name: 'img',
                attributes: ['txdam']
            },
            model: (viewElement, {writer}) => {
                const serverId = this.editor.config.get('ServerId');
                const newsPaperStore = this.editor.config.get('newsPaperStore');
                const newspaper = newsPaperStore.getNewspaperById(this.editor.NewspaperId);
                const base = "https://" + newspaper.main_domain;
                const url = new URL(viewElement.getAttribute('src'), base);
                if(url.searchParams.get('uid') === null) {
                    url.searchParams.append("uid", serverId + "-" + viewElement.getAttribute('txdam'));
                }
                const element = writer.createElement('imageInline', {src: url.toString() });
                return element;
            },
            converterPriority: 'high'
        } );
    }
}