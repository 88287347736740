
<script setup>
import { onMounted, ref, watch } from 'vue';
import CategoryTree from './CategoryTree.vue';
import { useNotification } from '@kyvg/vue3-notification';
import { usePublishedStore, useFilemountStore, useDraftStore, useNewsPaperStore, useAuthStore } from '../../stores';
import { storeToRefs } from 'pinia';
import { fetchWrapper } from '../../_helpers/fetch-wrapper';
import { useLoading } from '@/loader';
import { useI18n } from 'vue-i18n';


const { notify }  = useNotification();
const { t } = useI18n({})

const newsPaperStore = useNewsPaperStore();
const draftStore = useDraftStore();
const publishedStore = usePublishedStore();
const authStore = useAuthStore();

const { remoteDetail } = storeToRefs(publishedStore);
const filemountStore = useFilemountStore();
filemountStore.fetchFilemount(authStore.user.preferences.hideArchiveTree);
const loader = useLoading();

const props = defineProps(['newspaper', 'show']);
const emit = defineEmits(['modal-action']);

const activeTab = ref("newspaper");
const selectedFolder = ref(null);
const authors = ref([]);
const folderName = ref("");
const published_report = ref("");

// Template refs
const modal = ref(null);
const modalcontent = ref(null);

let modalPublish = null;

init();

function init() {
    draftStore.publishing = {};
    draftStore.publishing[newsPaperStore.selectedNewspaper] = new Object({
        id: 0,
        categories: [],
        openedTree: []
    });
    getAuthors(newsPaperStore.selectedNewspaper);
}

function newFolder() {
    loader.show({container: modalcontent.value });
    filemountStore.createFolder(selectedFolder, folderName).then( resp => {
        loader.hide();
        folderName.value = '';
    });
}

async function getAuthors(newspaper) {
    authors.value = await fetchWrapper.get(`${import.meta.env.VITE_API_URL}/journal/newspaper/${newspaper}/authors`);
}

onMounted(() => {
    modalPublish = new bootstrap.Modal(modal.value);
    $(modalPublish._element).on("hide.bs.modal", cancel);
    watch(() => props.show, (value) => {
        if (value) {
            start();
            modalPublish.show();
        }
        else {
            modalPublish.hide();
        }
    });
});

function done() {
    notify({
        text: "Notizia pubblicata",
        type: "success"
    });
    emit('modal-action', 'done');
}

function cancel() {
    emit('modal-action', 'close');
}

function start() {
    activeTab.value = 'start';
}

async function checkCategoryAndPublish() {
    if(checkCategorySelected()) {
        publish();
    }
}

async function publish() {
    return new Promise((resolve, reject) => {
        try {
            loader.show({container: modalcontent.value });
            publishedStore.saveRemoteAndClean(newsPaperStore.selectedNewspaper).then( (data) => {
                /*
                published_report.value = data;
                activeTab.value = 'report';
                */
                resolve();
                done()
            }).catch( (reason) => {
                notify({
                    text: "Errore nel salvataggio della notizia",
                    type: "error"
                });
                console.log(reason);
            }).finally(() => {
                loader.hide();
            });
        } catch(err) {
            notify({
                text: err.message,
                type: "error"
            });
            reject(e.message);
        }
    });
}

function checkCategorySelected() {
    if(!remoteDetail.value.categories || remoteDetail.value.categories.length == 0) {
        notify({
            title: 'Error',
            text: t('select-almost-one-category'),
            type: 'error'
        });
        return false;
    }
    return true;
}

function catSelect(cat_id) {
    let cat_only_id = cat_id.split("-")[2];
    if(remoteDetail.value.categories) {
        if(remoteDetail.value.categories?.indexOf(cat_only_id) === -1) {
            remoteDetail.value.categories.push(cat_only_id)
        } else {
            remoteDetail.value.categories.splice(remoteDetail.value.categories.indexOf(cat_only_id), 1);
        }
    } else {
        remoteDetail.value.categories = [];
        remoteDetail.value.categories.push(cat_only_id);
    }
}

</script>

<template>
    <div class="modal fade" ref="modal"  tabindex="-1" role="dialog" data-backdrop="static">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content" ref="modalcontent">
            <div class="modal-body">
                <div class="container h-100">
                    <div v-if="newsPaperStore.selectedNewspaper && !newsPaperStore.selectedNewspaper.endsWith('-0')" class="tab-content" id="myTabContent">
                        <div :class="(activeTab == 'start' ? 'tab-pane fade active show' : 'tab-pane fade')" :id="newsPaperStore.selectedNewspaper" role="tabpanel" ref="newspaper">
                            <h2>Configura <mark>{{ newsPaperStore.getNewspaperById(newsPaperStore.selectedNewspaper).name }}</mark></h2>
                            <div v-if="remoteDetail" class="row mt-3">
                                <div class="col-lg-3">
                                    <CategoryTree :openedTree="draftStore.publishing[newsPaperStore.selectedNewspaper]?.openedTree" :republish=ref(true) :categories="newsPaperStore.getNewspaperById(newsPaperStore.selectedNewspaper).categories" :newspaper_id="newsPaperStore.selectedNewspaper" :level=1 :opened=true @catselect="catSelect" />
                                </div>
                                <div class="col-lg-9">
                                    <div class="form-group">
                                        <label for="title">Titolo</label>
                                        <input type="text" class="form-control" id="title" v-model="remoteDetail.title">
                                    </div>
                                    <div class="form-group">
                                        <label for="short">Sottotitolo</label>
                                        <input type="text" class="form-control" id="short" v-model="remoteDetail.short">
                                    </div>
                                    <div class="form-group">
                                        <label for="short">Parole chiave</label>
                                        <input type="text" :disabled=" (remoteDetail.type == 0) ? false : true " class="form-control" id="keywords" v-model="remoteDetail.keywords">
                                    </div>
                                    <div class="form-group">
                                        <label for="author">Autore</label>
                                        <v-select :disabled=" (remoteDetail.type == 0) ? false : true " v-model="remoteDetail.tx_ttnewsauthor_render" :value="remoteDetail.tx_ttnewsauthor_render" :options="authors" label="label" :reduce="(el) => el.id" ></v-select>
                                        <input type="text" :disabled=" (remoteDetail.type == 0) ? false : true " class="form-control" id="author" v-model="remoteDetail.author">
                                    </div>
                                    <div class="form-group">
                                        <label for="author">Posizione</label>
                                        <div class="box-positions">
                                            <div v-for="(position, index) in newsPaperStore.positions" :key="position.id" class="clearfix">
                                                <div class="icheck-primary d-inline">
                                                    <input type="checkbox" class="form-check-input" :true-value="[]" :id="`position-${position.id}`" v-model="remoteDetail.positions" :value="position.id">
                                                    <label class="form-check-label" :for="`position-${position.id}`">{{position.name}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-end gx-5">
                                <div class="col-auto">
                                    <button class="btn btn-secondary" @click="cancel()">Annulla</button>
                                </div>
                                <div class="col-auto">
                                    <!-- button v-if="uploadBackends.length > 0" class="btn btn-success" @click="selectUploadFolder()">Ora seleziona la cartella di upload</button -->
                                    <button class="btn btn-success" @click="checkCategoryAndPublish()">Pubblica</button>
                                </div>
                            </div>
                        </div>
                        <!--                        <div  v-for="backend in uploadBackends" :class="( (activeTab == 'select-folder') ? 'tab-pane fade active show' : 'tab-pane fade')" :id="backend" role="tabpane" ref="backend">
                            <h2>Cartella di upload su <mark>{{ getBackendName(backend) }}</mark></h2>
                            <p>Seleziona la cartella nella quale verrano caricate le immagini e gli allegati dell'articolo sul server.</p>
                            // TODO BEGIN
                            <div class="form-group">
                                <div class="row mt-3">
                                    <div class="col-lg-2 col-12">
                                        <label for="short">Nuova cartella</label>
                                    </div>
                                    <div class="col-lg-4 col-8 ">
                                        <input v-model="folderName" type="text" class="form-control" />
                                    </div>
                                    <div class="col-lg-6 col-4">
                                        <button class="btn btn-primary" @click="newFolder">Crea</button>
                                    </div>
                                </div>
                            </div>
        
                                <ul class="filemount-list">
                                    <li :class="(isFolderSelected(filemount.id) ? 'filemount-item selected' : 'filemount-item')" v-for="filemount in currentFilemount(backend)">
                                        <span class="filemount" @click="folderSelect(filemount.id)">{{ filemount.name }}</span>
                                        <FolderTree :checkSelected="isFolderSelected"  :filemount_id="filemount.id" :baseUrl="filemount.base_url" :parentPath="filemount.id" :folders="filemount.root" :level=1 :opened=true @folderselect="folderSelect" />
                                    </li>
                                </ul>
                                <div class="row justify-content-end gx-5">
                                    <div class="col-auto">
                                        <button class="btn btn-secondary" @click="cancel()">Annulla</button>
                                    </div>
                                    <div class="col-auto">
                                        <button class="btn btn-primary" @click="start()">Indietro</button>
                                    </div>
                                    <div class="col-auto">
                                        <button class="btn btn-success" @click="publish()">Pubblica</button>
                                    </div>
                                </div>
                            
                            //TODO END
                        </div>
                        -->
                        <!-- div :class="(activeTab == 'report' ? 'tab-pane fade active show' : 'tab-pane fade')" id="report" role="tabpanel" aria-labelledby="report-tab">
                            <h3 class="mb-4">Elenco pubblicazioni</h3>
                            <div v-if="activeTab == 'report'" class="published-list">
                                <p :class="(published_report.errors.length > 0 ?'error' : 'success')">
                                    <strong>{{ newsPaperStore.getNewspaperById(newsPaperStore.selectedNewspaper).name }}: </strong>
                                    <template v-if="published_report.errors.length > 0">Codice errore {{ published_report.errors }}</template>
                                    <template v-else>{{ published_report.data.link }}</template>
                                </p>
                            </div>
                            <div class="row justify-content-end gx-5">
                                <div class="col-auto">
                                    <button class="btn btn-secondary" @click="done()">Chiudi</button>
                                </div>
                            </div>
                        </div -->
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>
