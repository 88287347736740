<script setup>
import { ref, reactive, onMounted, computed } from 'vue'
import { useDragAndDrop } from "@formkit/drag-and-drop/vue";
import { multiDrag, selections } from "@formkit/drag-and-drop";

// Props
const props = defineProps({
    store: {
        type: Object
    },
    media_server: {
        type: String
    },
    error: '',
    server: {
        type: String,
        default: '/api/upload',
    },
    media_file_path:{
        type: String,
    },
    disabled: {
        type: Boolean,
        default: false,
    }
})

// Emits
const emit = defineEmits([
    'added-media',
    'deleted-media',
    'saved-media',
    'ondambrowse'
]);

// Data
const loading = ref(true)
const showDamBrowser = ref(false)
const forbiddenExtensions = reactive([
    'php',
    'php3',
    'exe',
    'com',
    'bat',
])
const didascaliaImmagini = ref("")

const modal_browser = ref(null)

onMounted( () => {
    $(modal_browser).on("hide.bs.modal", closeBrowser);
})


// Methods

const canMove = (evt, originalEvent) => {
    if(props.disabled) return false
    return true;
}

const applyCaption = () => {
    // Find image without caption and add them
    props.store.draftDetailImages.images.forEach( (item, index) => {
        if(item.caption) {
            if(item.caption.trim() == "") {
                item.caption = didascaliaImmagini.value;
            }
        } else {
            item.caption = didascaliaImmagini.value;
        }
    });
    didascaliaImmagini.value = "";
}

function getMediaUrl(media) {
    let url;
    if(media.is_remote) {
        url = media.path; 
    } else if(media.path) {
        url = props.media_file_path +'/'+ media.path;
    } else if(media.orig) {
        url = media.url;
    } else {
        // New remote added image: prepend backend public URL
        url = media.publicUrl + media.url;
    }
    return url;
}

async function fileChange(event) {
    loading.value = true
    let files = event.target.files
    for(var i=0; i < files.length; i++){
        var re = /(?:\.([^.]+))?$/;
        var extension = re.exec(files[i].name);
        if( forbiddenExtensions.indexOf(extension[1]) == -1 ) {
            //let formData = new FormData
            let url = URL.createObjectURL(files[i])
            //formData.set('image', files[i])
            //const {data} = await axios.post(this.server, formData)
            //props.store.draftDetailImages.added_media.push(files[i]);
            props.store.draftDetailImages.images.push({url:url, name:files[i].name, size:files[i].size, type:files[i].type, orig:files[i], caption:''});
        } else {
            this.$notify({
                'type': 'error',
                'title': 'Errore caricamento',
                'text': 'Estensione file non permessa: <strong>' + extension[1] + '</strong>'
            });
        }
    }
    loading.value = false
    media_emit()
}

async function damBrowser() {
    if(showDamBrowser) {
        showDamBrowser.value = false;
    }
    showDamBrowser.value = true;
    emit('ondambrowse', showDamBrowser);
}

function closeBrowser() {
    showDamBrowser.value = false;
}



function remove(element) {
    if(element.id) {
        props.store.draftDetailImages.deleted_media.push(element);
    }
    props.store.draftDetailImages.images.splice(props.store.draftDetailImages.images.indexOf(element),1)
    media_emit()
}

function media_emit() {
    emit('added-media',props.store.draftDetailImages.added_media)
    emit('deleted-media', props.store.draftDetailImages.deleted_media)
    emit('saved-media', props.store.draftDetailImages.saved_media)
}


const imagesData = computed({
    get() {
        return props.store.draftDetailImages.images ?? [];
    },
    set(newValue) {
        props.store.draftDetailImages.images = newValue;
    }  
});


const [ dragImagesEl, images] = useDragAndDrop(imagesData, {
    group: "images",
    plugins: [
        multiDrag({
            plugins: [
                selections({
                    selectedClass: "sortable-selected",
                }),
            ],
        }),
    ],
});

</script>

<template>
    <div>
        <div class="form-group">
            <div class="card" :class="error?'red-border':''">
                <div class="card-body">
					<div class="btn-group mb-3">
						<div v-if="!props.disabled" class="btn btn-default btn-sm">
							<i class="fas fa-folder-open" @click="damBrowser" title="Sfoglia dall'archivio media"></i>
						</div>
					
						<div v-if="!props.disabled" class="btn btn-default btn-sm">
							<label for="images-upload" style="margin:0;">
								<i class="fas fa-file-upload" title="Carica nuova"></i>
							</label>     
							<input @change="fileChange" id="images-upload" type="file" accept="image/*" multiple hidden>
						</div>
					</div>
                    
                    
                    <!--IMAGES PREVIEW-->
                    <div ref="dragImagesEl" class="scrollable-box">
                            <div v-for="element in images" :key="element" class="image-container image-margin">
                                
                                <img :src="getMediaUrl(element)" alt=""  class="images-preview">
                                <button v-if="!disabled" @click="remove(element)" class="close-btn" type="button">
                                    <svg 
                                        class='times-icon' 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        width="0.65em" 
                                        height="0.65em" 
                                        preserveAspectRatio="xMidYMid meet" 
                                        viewBox="0 0 352 512">
                                            <path 
                                                d="m242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28L75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256L9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                                                fill="currentColor"    
                                            />
                                    </svg>
                                </button>
                                <div class="grow-wrap"><textarea v-model="element.caption" class="image-caption" placeholder="Didascalia"></textarea></div>
                                <div style="width:calc(100% - 140px - 3rem); margin:0.5rem 0 0 0.5rem;">{{element.file_name ?? element.name}}</div>
                                <!-- input type="text" class="image-caption" -->
                                <div class="selected-overlay"></div>
                            </div>
                        </div>

					<!--
					<label>Didascalia immagini<br><small>(applica la didascalia inserita a tutte le immagini che ne sono sprovviste)</small></label>
					<input type="text" class="form-control" v-model="didascaliaImmagini">
					<button @click.prevent="applyCaption()" class="btn btn-secondary">Applica</button>
					-->
					<div v-if="!props.disabled && images && images.length > 1" class="mt-2">
					  <label for="search">Didascalia <small>(applica questa didascalia a tutte le immagini che ne sono sprovviste)</small></label>
						<div class="input-group">
						  <input type="text" class="form-control" v-model="didascaliaImmagini">
						  <div class="input-group-prepend">
						  <button class="btn btn-primary" @click.prevent="applyCaption()" >
						  <i class="fas fa-check"></i>
						  </button>
						</div>
					  </div>
					</div>
                </div>
			</div>
            
        </div>
        <div v-if='error' id="media-required">
            <p class='red-text'>{{error}}</p>
        </div>
        <div v-for="(image, index) in props.store.draftDetailImages.deleted_media" :key="index" class="m-top">
            <input type="text" name="deleted_media[]" :value="image.name" hidden>
        </div>
    </div>
</template>

<style scoped>
.grow-wrap {
  /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
  display: grid;
  position: absolute;
  bottom: 0;
  font-size: .8rem;
  width: 100%;
  z-index:1;
}
.grow-wrap::after {
  /* Note the weird space! Needed to preventy jumpy behavior */
  content: attr(data-replicated-value) " ";

  /* This is how textarea text behaves */
  white-space: pre-wrap;

  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
}
.grow-wrap > textarea {
  /* You could leave this, but after a user resizes, then it ruins the auto sizing */
  resize: none;

  /* Firefox shows scrollbar on growth, you can hide like this. */
  overflow: hidden;
}
.grow-wrap > textarea,
.grow-wrap::after {
  /* Identical styling required!! */
  border: 1px solid black;
  /* padding: 0.5rem; */
  font: inherit;

  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
}
.scrollable-box {
    display:flex;
    flex-wrap:wrap;
    max-height:390px;
    width:100%;
    overflow-y:auto;
}
.image-wraper{
    min-height: 200px !important;
}

.images-upload {
    background-color: #ffffff !important;
    border-radius: 5px !important;
    border: 1px dashed #ccc !important;
    display: inline-block !important;
    cursor: pointer !important;
    width: 165px !important;
    height: 88px !important;
}
.images-upload:hover{
    background-color: #f1f1f1 !important;
}

.image-container{
	display: flex !important;
    height: 130px !important;
    width: 100% !important;
    position: relative;
	margin-bottom: 0.5rem !important;
	margin-right: 0.3rem !important;
	background: #EEE;
	cursor: move !important;
}

.image-container .selected-overlay {
    display:none;
    background-color: rgba(0, 123, 255, 0.1);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.image-container.sortable-selected .selected-overlay {
    display: inline-block;
}

.images-preview {
    /*border-radius: 5px !important;*/
    /*border: 1px solid #ccc !important;*/
    display: inline-block !important;
    width: 140px !important;
    height: 86px !important;
    padding-top: -14px !important;
    transition: filter 0.1s linear;
    object-fit: cover;
}
.images-preview:hover{
    filter: brightness(90%);
}
.image-caption {
    padding: 0.1rem 0.2rem;
    border: 1px solid #EEE!important;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.button-container{
    display: inline-flex !important;
    height: 90px !important;
    width: 140px !important;
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
}

.close-btn{
    background: none !important;
	color:red !important;
	border: none !important;
	padding: 0px !important;
    margin:0px !important;
	font: inherit !important;
	cursor: pointer !important;
	outline: inherit !important;
    position: absolute !important;
    right: 25px !important;
    top: 0 !important;
    width: 0px !important;
	z-index:1;
}
.times-icon{
    font-size: 2rem !important;
    padding: 0px !important;
    margin:0px !important;
}
.custom-icon{
    color: #007bff !important;
    font-size: 3rem !important;
    margin-top: 18px !important;
    margin-left: 44px !important;
    
}

.close-btn:hover{
    color: rgb(190, 39, 39) !important;
}


/* -------------------- */


.red-border {
    border: 1px solid #dc3545 !important;
    border-color: #dc3545 !important;
}
.red-text {
    color: #d82335;
}

</style>
