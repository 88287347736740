<script setup>
import FolderItem from './FolderItem.vue';
</script>


<template>
      <ul class="folders" :class="(opened ? 'opened' : 'closed')">
      <template v-for="(folder) in folders" :key="parentPath + '/' + folder.name">
        <FolderItem @beforemount="childBeforeMount" @mounted="childMounted"  :checkSelected="checkSelected" :baseUrl="baseUrl" :parentPath="parentPath" :folder="folder" :level=level+1  :filemount_id="filemount_id" @folderselect="childFolderSelect" @opening="(status) => childOpening(status)" />
      </template>
  </ul>
</template>

<script>
const baseUrl = `${import.meta.env.VITE_API_URL}`;

export default {
    emits: [
        "load-news",
        "folderselect",
        "beforemount",
        "mounted",
        "opening"
    ],
    props: {
        folders: Array,
        level: Number,
        filemount_id: String,
        opened: Boolean,
        parentPath: String,
        baseUrl: String,
        checkSelected: {
            type: Function,
            default: null,
        }
    },
    data() {
        return {
            subOpened: false,
            path: '',
            childMounting: 0,
        };
    },
    computed: {

    },
    methods: {
        childOpening(status) {
            this.$emit("opening", status);
        },
        async childBeforeMount() {
            this.$emit('beforemount');
        },
        async childMounted() {
            this.$emit('mounted');
        },
        childFolderSelect(path) {
            this.$emit("folderselect", path);
        }
    },
    components: { FolderItem }
}
</script>
