<script setup>
import { useFilemountStore, useBackendStore } from '@/stores';
import FolderTree from './FolderTree.vue';
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { useLoading } from '@/loader';
import FilemountVue from './Filemount.vue';
import { nextTick } from 'vue';
import TableLite from '../table-lite/src/components/TableLite.vue';
import { format, parseJSON } from 'date-fns';
import { useAuthStore, useNewsPaperStore } from '../../stores';
const filemountStore = useFilemountStore();
const backendStore = useBackendStore();
const authStore = useAuthStore();


</script>

<template>
    <div v-if="show" ref="dambrowser" class="container-fluid">
        <div class="row">
            <div class="col-12 col-md-4 col-lg-3">                
                <div class="form-group">
                    <label for="file_upload">File upload</label>
                    <div class="input-group">
                        <div class="custom-file">
                            <input class="custom-file-input" id="file_upload" ref="fileupload" type="file" accept="*/*" multiple>
                            <label class="custom-file-label" for="file_upload">Seleziona file</label>
                        </div>
                        <div class="input-group-append">
                           
                           <span class="input-group-text" @click="doUpload">Upload</span>
                        </div>
                    </div>
                </div>
                
                <div id="filemounts">
                    <template v-if="preferredNewspapers.length > 0">
                        <strong>Preferiti</strong>
                        <ul class="filemount-list pref-filemount-list">
                            <template v-for="newspaper_id in preferredNewspapers">
                                <FilemountVue  :filemount="getNewspaperFilemount(newspaper_id)" @folderselect="folderSelect" @opening="(status) => onDamLoading(status)" />
                            </template>
                        </ul>
                    </template>
                    <ul class="backend-list">
                        <li class="backend-item" v-for="(backend, backend_id) in filemountStore.filemount">
                            <i v-if="Object.keys(backend).length > 0" :class="(backend.opened ? 'far fa-folder-open mr-1' : 'far fa-folder-closed mr-1')" @click="toggleBackendStatus(backend)"></i>
                            <strong>{{ backendStore.getBackendName(backend_id) }}</strong>
                            <ul v-if="backend.opened" class="filemount-list">
                                <template v-for="(filemount, index) in backend">
                                <FilemountVue v-if="typeof filemount == 'object'" :filemount="filemount" @folderselect="folderSelect" @opening="(status) => onDamLoading(status)" />
                                </template>
                            </ul>
                        </li> 
                    </ul>
                </div>
                
                <div v-if="!authStore.user.preferences.hideArchiveTree" class="form-group">
                    <label for="new_folder">Nuova cartella</label>
                    <div class="input-group mb-3">
                        <input type="text"  id="new_folder" class="form-control" v-model="folderName">
                        <div class="input-group-prepend">
                            <button class="btn btn-primary" @click.prevent="newFolder()">Crea</button>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-12 col-md-8 col-lg-9">
                <div class="form-group">
                    <label for="search">Cerca</label>
                    <div class="input-group mb-3">
                        <input type="search" v-model="searchTerm"  id="search" class="form-control" @search="searchClick()" >
                        <div class="input-group-prepend">
                            <button class="btn btn-primary" @click.prevent="searchClick()"><i class="fas fa-search"></i></button>
                        </div>
                    </div>
                </div>
                <div class="wrapper">
                    <button v-if="(context !== 'none' && context !== 'upload')" class="mb-2 btn btn-success" @click.prevent="addSelected">+ Aggiungi selezionati</button>
                    <table-lite ref="imageTable" :has-checkbox="(context !== 'none' && context !== 'upload')" :is-slot-mode="true" :rows="imageList" checkedReturnType="key" @return-checked-rows="updateCheckedRows"  :columns="tableCols" :total="pagination.totalResult" @do-search="doSearch" :sortable="{order: 'tstamp', sort: 'desc'}" >
                        <template v-slot:uid="data">
                            <div class="image-item">
                                <img :src="getFileThumb(data.value)" />
                            </div>
                        </template>
                        <template v-slot:file_name="data">
                            <div class="filename-item">
                                {{ data.value.file_name }}
                            </div>
                        </template>
                        <template v-slot:tstamp="data">
                            <div class="filename-item">
                                {{  formatDateTime(data.value.tstamp) }}
                            </div>
                        </template>
                    </table-lite>
                    <button v-if="(context !== 'none' && context !== 'upload')" class="mb-2 btn btn-success" @click.prevent="addSelected">+ Aggiungi selezionati</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            imageList: [],
            currentFilemountId: null,
            isLoading: false,
            pagination: {
                currentPage: 1,
                totalPages: 0
            },
            folderName: '',
            opened: false,
            searchTerm: '',
            damLoader: useLoading(),
            authStore: useAuthStore(),
            damLoading: false,
            tableCols: [
                { label: "", width: "20%", field: "uid", isKey: true },
                { label: "Nome", width: "50%", field: "file_name", sortable: true },
                { label: "Data", width: "30%", field: "tstamp", sortable: true },
            ],
            dateFormat: "dd/MM/yyyy HH:mm",
            selectedRows: []
        }
    },
    emits: [
        'closed',
        'hidebsmodal'
    ],
    props: {
        "show": {
            "type": Boolean,
            "default": false
        },
        "store": {
            "type": Object,
            "default": {}
        },
        /**
         * Available "context"
         *  'upload': click on "Media" navbar link
         *  'attach': click on "browse folder" from Images on Edit News
         *  'file': click on "browse folder" from Attachments on Edit News
         * 'inline': click on "browse folder" from RTE toolbar
         */
        "context": {
            "type": String,
            "default": "attach"
        }
    },
    mounted() {
        /*
        this.modalBrowser = new bootstrap.Modal(this.$refs.modal);
        $(this.modalBrowser._element).on('hide.bs.modal', this.closeBrowserModal);
        */
        
   
    },
    methods: {

        toggleBackendStatus(backend) {
            /*
            if(!backend.opened) {
                this.isOpening = true;
                this.$emit("opening", this.isOpening);
            } else {
                //this.isClosing = true;
            }
            */
            backend.opened = !backend.opened;
        },
        getNewspaperFilemount(newspaper_id) {
            const newspaperStore = useNewsPaperStore();
            const filemountStore = useFilemountStore();
            
            let newspaper = newspaperStore.getNewspaperById(newspaper_id);
            let filemount = filemountStore.getFilemountById(newspaper['backendId'] + '-' + newspaper['file_mountpoints']);
            return filemount;
        },
        updateCheckedRows(rowsKeys) {
            this.selectedRows = rowsKeys;
        },
        onDamLoading(status) {
            if(status) {
                this.damLoading = true;
            } else {
                this.damLoading = false;
            }
        },
        doSearch(offset, limit, order, sort) {
            this.searchImages(false, offset, limit, order, sort);
        },
        searchClick() {
            const filemountStore = useFilemountStore();
            if(!filemountStore.lastPath) {
                this.$notify({
                    text: 'Selezionare prima una cartella!',
                    type: 'warn'
                });
                return;
            }
            if(this.$refs.imageTable.setting.page == 1) {
                this.doSearch(0, this.$refs.imageTable.setting.pageSize, this.$refs.imageTable.setting.order, this.$refs.imageTable.setting.sort);
            } else {
                this.$refs.imageTable.setting.page = 1;
            }
        },
        async getFilemount() {
            const filemountStore = useFilemountStore();
            const authStore = useAuthStore();
            this.damLoader.show({container: this.$refs.dambrowser });
            let waiters = [];
            await filemountStore.fetchFilemount(this.authStore.user.preferences.hideArchiveTree);

            if(authStore.user.preferences?.newspapers.length == 0) {
                for (const [key, backend] of Object.entries(filemountStore.filemount)) {
                    backend.opened = true;
                }
            }

            /**
             * No auto search image
             */
            /*
            if(filemountStore.lastPath) {
                let parts = filemountStore.lastPath.split("/");
                let [backend_id, filemount_id] = parts[0].split("-");
                Object.entries(filemountStore.filemount[backend_id]).find((value, index) => {
                    if(value[1].id == parts[0]) {
                        waiters.push(this.searchImages(true));
                    }
                });
            }
            Promise.all(waiters).then( val => {
                this.damLoader.hide();
            });
            */
            this.damLoader.hide();
        },
        getFileThumb(image) {
            const parts = image.file_name.split('.');
            let ext = '';
            if(parts.length > 1) {
                ext = parts[parts.length - 1].toLowerCase();
            }
            if(Array('png', 'jpg', 'jpeg', 'bmp', 'tiff', 'gif', 'webp', 'jfif').includes(ext)) {
                const filemountStore = useFilemountStore();
                let parts = this.currentFilemountId.split("-")
                for( let [filemount_name, filemount] of Object.entries(filemountStore.filemount[parts[0]])) {
                    if(this.currentFilemountId == filemount.id) {
                        return `${filemount.baseUrl}/${image.file_path}${image.file_name}`
                    }
                }
                //return `${filemountStore.filemount[this.currentFilemountId].baseUrl}/${image.file_path}${image.file_name}`
            }
            return new URL('../../assets/file_generic.svg', import.meta.url).href;
        },
        formatDateTime(inputDate) {
            let date;
            try {
                date = format(parseJSON(inputDate), this.dateFormat);
            } catch(err) {
                console.warn("Error formatting date", err, inputDate);
                date = '';
            }
            return date;
        },
        doUpload() {
            let fileupload = this.$refs.fileupload;
            if(fileupload.files.length == 0) {
                this.$notify({
                    text: 'Selezionare prima un file!',
                    type: 'warn'
                });
                return;
            }
            const filemountStore = useFilemountStore();
            if(!filemountStore.lastPath) {
                this.$notify({
                    text: 'Selezionare prima una cartella!',
                    type: 'warn'
                });
                return;
            }
            this.damLoading = true;
            
            let waiters = [];
            for(var i=0; i < fileupload.files.length; i++){
                let formData = new FormData
                formData.set('file', fileupload.files[i])
                
                waiters.push(filemountStore.uploadFile(formData))
            }
            Promise.all(waiters).then( val => {
                if(filemountStore.uploadErrors.length > 0) {
                    this.$notify({
                        text: filemountStore.uploadErrors,
                        type: 'error'
                    });
                } else {
                    this.searchImages();
                }
                fileupload.value = '';
                this.damLoading = false;
            });
        },
        newFolder() {
            const filemountStore = useFilemountStore();
            if(filemountStore.lastPath) {
                this.damLoading = true;
                filemountStore.createFolder(filemountStore.lastPath, this.folderName).then( resp => {
                    this.folderName = '';
                }).catch((reason) => {
                    this.$notify({
                    text: 'Errore del server. Codice: ' + reason,
                    type: 'error'
                });
                }).finally(() => {
                    this.damLoading = false;
                });
            } else {
                this.$notify({
                    text: 'Selezionare prima una cartella!',
                    type: 'warn'
                });
            }

        },
        pageUpdated(pagenum) {
            this.searchImages();
        },
        closeBrowserModal(e) {
            this.$emit('hidebsmodal');
        },  
        addSelected() {
            // Find selected images
            this.selectedRows.forEach((image_uid, index) => {
                this.addImage(this.imageList.find(item => item.uid == image_uid));
            });
            this.closeBrowserModal(null);
        },
        addImage(image) {
            const filemountStore = useFilemountStore();
            let storeImage = JSON.parse(JSON.stringify(image));
            storeImage.type = "remote";
            //storeImage.url = `${filemountStore.filemount[this.currentFilemountId].baseUrl}/${image.file_path}${image.file_name}`;
            storeImage.url = `${image.file_path}${image.file_name}`;
            let parts = this.currentFilemountId.split("-")
            for( let [filemount_name, filemount] of Object.entries(filemountStore.filemount[parts[0]])) {
                if(this.currentFilemountId == filemount.id) {
                    storeImage.remote_id = filemount.backendId + "-" + storeImage.uid;
                    storeImage.publicUrl = `${filemount.baseUrl}`;
                }
            }

            if(this.context == "attach") {
                if(!this.store.draftDetailImages.images.find((el) => {
                    return el.remote_id === storeImage.remote_id;
                })) {
                    this.store.draftDetailImages.images.push(storeImage);
                }
            } else if(this.context == "file") {
                if(!this.store.draftDetailFiles.files.find((el) => {
                    return el.remote_id === storeImage.remote_id;
                })) {
                    this.store.draftDetailFiles.files.push(storeImage);
                }
            } else {
                this.$emit('addimage', storeImage);
                this.$emit('closing');
            }
        },
        isSelected(id) {
            const filemountStore = useFilemountStore();
            return id == filemountStore.lastPath;
        },
        async folderSelect(path) {
            // From CategoryTree
            const filemountStore = useFilemountStore();
            if(path != filemountStore.lastPath) {
                filemountStore.lastPath = path;
                this.pagination.currentPage = 1;
                // List image only when click on search lens
                //this.searchClick();
                this.imageList = [];     
            }
        },
        searchImages(loader = false, offset, limit, order, sort) {
            return new Promise((resolve, reject) => {
                const filemountStore = useFilemountStore();
                if(filemountStore.lastPath) {
                    this.imageList = [];
                    if(!loader) {
                        this.damLoading = true;
                    }
                    filemountStore.searchImages(filemountStore.lastPath, this.searchTerm, offset, limit, order, sort).then( response => {
                        this.imageList = response['images'];
                        this.pagination.totalPages = response['totalPages'];
                        this.pagination.currentPage = response['currentPage'];
                        this.currentFilemountId = response['filemount_id'];
                        this.pagination.totalResult = response['totalResult'];
                        resolve();
                    }).catch((reason) => {
                        reject("Errore nel caricamento delle immagini");
                    }).finally(() => {
                        if(!loader) {
                            this.damLoading = false;
                        }
                    });
                } else {
                    reject("Seleziona prima una cartella")
                }
            });
        },
    },
    watch: {
        damLoading(value) {
            if(value) {
                this.damLoader.show({container: this.$refs.dambrowser });
            } else {
                this.damLoader.hide();
            }
        },
        show(value) {
            if (value) {
                setTimeout(() => {
                    nextTick(() => {
                        this.getFilemount();
                        bsCustomFileInput.init();
                    });
                },100);

                 
                //this.modalBrowser.show();
            }
            else {
                this.$emit('closed');
            }
        }
    },
    computed: {
        preferredNewspapers() {
            const authStore = useAuthStore();
            const newsPaperStore = useNewsPaperStore();

            let newspapers = [];
            if(authStore.user?.preferences?.newspapers?.length > 0 && newsPaperStore.newspaper && Object.keys(newsPaperStore.newspaper).length > 0) {
                const filtered = authStore.user.preferences.newspapers.filter( (newspaper_id) => {
                let parts = newspaper_id.split('-');
                if(parts[0] in newsPaperStore.newspaper) {
                    for(const [name, newspaper] of Object.entries(newsPaperStore.newspaper[parts[0]])) {
                    if(newspaper.uid == parts[1]) {
                        return true;
                    }
                    }
                }
                return false;
                });
                newspapers = filtered;
            }

            return newspapers;
        }
    },
    components: { FolderTree, FilemountVue }
}

</script>
<style>
#filemounts {
  max-height: 75vh;
  overflow-y: scroll;
}

li.filemount-item,
li.backend-item {
    list-style-type: none;
}

ul.filemount-list,
ul.backend-list,
ul.folders {
  padding-inline-start: 18px;
}
#filemounts > ul.filemount-list,
#filemounts > ul.backend-list,
#filemounts > ul.folders {
  padding-inline-start: 0px;
}

ul.folders {
  font-size: 14px;
}

.filemount-item > span.filemount,
.folder-item > span.folder {
  cursor:pointer;
}
.folder-item.selected > span.folder,
.filemount-item.selected > span.filemount {
  font-weight:bold;
}

.image-item {
    text-align: center;
}

.image-item img {
    min-width: 100px;
    max-width: 100px;
    max-height: 100px;
}

.modal-content .wrapper {
    min-height: 400px;
}

.Page {
    width:auto!important;
}

.times-icon {
    background: #fd7e14;
    color: #FFFFFF;
}

.custom-file-label::after {
    display: none;
}
</style>
