<script setup>
import { useLoading } from '@/loader';
import { useNewsPaperStore, usePublishedStore } from '@/stores';
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
//import CKEditorInspector from '@ckeditor/ckeditor5-inspector';
import Datepicker from '@vuepic/vue-datepicker';
import enabledConfig from '@/ckeditor5/config/enabled';
import { useNotification } from '@kyvg/vue3-notification';
import DamImageBrowser from '../../components/newspaper/DamImageBrowser.vue';
import DamImages from '../../components/newspaper/DamImages.vue';
import DamFiles from '../../components/newspaper/DamFiles.vue';
import PublishPublished from '../../components/newspaper/PublishPublished.vue';
import RelatedRemoteNews from '@/components/newspaper/RelatedRemoteNews.vue';
import RelatedRemoteBrowser from '../../components/newspaper/RelatedRemoteBrowser.vue';
//import CKEditorInspector from '@ckeditor/ckeditor5-inspector';

const loader = useLoading();
const publishedStore = usePublishedStore();
const newsPaperStore = useNewsPaperStore();
const { remoteDetail } = storeToRefs(publishedStore);
const route = useRoute();
const router = useRouter();

const { notify }  = useNotification();

// Template refs
const relatedBrowser = ref(null);
const damBrowser = ref(null);

// DateTime Picker
const textInputOptions = {
    format: "dd/MM/yyyy HH:mm"
};

let modalRelatedBrowser = null;
let modalDamBrowser = null;

// Component States
const editorData = ref("");
const editor = ClassicEditor
let bodytextEditorConfig = enabledConfig;
// Disattivo le immagini inline (per ora)
bodytextEditorConfig['dam-image']['click'] = onDamBrowse;
bodytextEditorConfig.ServerId = route.params.newspaperid.split("-")[0];
bodytextEditorConfig.newsPaperStore = newsPaperStore;
/*
bodytextEditorConfig['toolbar']['items'] = bodytextEditorConfig['toolbar']['items'].filter((item, index) => {
    return !(item == 'uploadImage')
});
*/
onMounted( async () => {
    modalDamBrowser = new bootstrap.Modal(damBrowser.value);
    modalDamBrowser._config.keyboard = false;
    $(modalDamBrowser._element).on('hide.bs.modal', onDamClosing);

    modalRelatedBrowser = new bootstrap.Modal(relatedBrowser.value);
    modalRelatedBrowser._config.keyboard = false;
    $(modalRelatedBrowser._element).on('hide.bs.modal', onRelatedClosing)
    await initRemoteDetail();
});


// Remote news
async function initRemoteDetail(remote_id) {
    loader.show();
    if(!remote_id && route.params.id) {
        remote_id = route.params.id;
    }
    //publishedStore.fetchRemotePublishedDetail(remote_id)
    publishedStore.fetchRemoteDetail(route.params.newspaperid, remote_id)
    .then((data) => {
        editorData.value = publishedStore.remoteDetail.bodytext;
        bodytextEditor.value.instance.draftId = publishedStore.remoteDetail.id;
        if(publishedStore.remoteDetail.starttime != null) {
            enabledDate.value = false;
        }
    }).catch((err) => {
        notify({
            text: "Errore nel caricamento della notizia pubblicata",
            type: "danger"
        });
        console.log("Error loading remote news", err);
    }).finally( () => {
        loader.hide();
    });
}

function closeNews() {
    router.push({ name: "draft-list-newspaper", params: { newspaperid: route.params.newspaperid } });
}

function saveNewsAndClose() {
    saveNews().then( (res) => {
        publishedStore.resetLocalStore();
        closeNews();
    });
}

function saveNews() {
    publishedStore.remoteDetail.bodytext = editorData;
    return new Promise((resolve, reject) => {
        try {
            loader.show();
            publishedStore.saveRemote(newsPaperStore.selectedNewspaper).then( (data) => {
                /*
                published_report.value = data;
                activeTab.value = 'report';
                */
                notify({
                    text: "Notizia pubblicata",
                    type: "success"
                });
                resolve();
            }).catch( (reason) => {
                notify({
                    text: "Errore nel salvataggio della notizia",
                    type: "error"
                });
                console.log(reason);
            }).finally(() => {
                loader.hide();
            });
        } catch(err) {
            notify({
                text: err.message,
                type: "error"
            });
            reject(e.message);
        }
    });
}

// CKEditor
const bodytextEditor = ref(null);

async function publishInlineImage(file) {
    return new Promise( ( resolve, reject ) => {
        publishedStore.uploadImage(file, route.params.newspaperid).then( (data) => {
            resolve(data);
        }).catch( (reason) => {
            reject(error);
        });
    });
}

function onBodytextEditorReady(evt) {
    bodytextEditor.value.instance.model.on('_afterChanges', evt => {
        FB.XFBML.parse();
    });
    //bodytextEditor.value.instance.saveDraft = saveNews;
    //bodytextEditor.value.instance.uploadImage = publishedStore.publishInlineImage;
    bodytextEditor.value.instance.uploadImage = publishInlineImage;
    bodytextEditor.value.instance.NewspaperId = route.params.newspaperid;
    //CKEditorInspector.attach( bodytextEditor.value.instance );
}

// Image management
const showDamBrowser = ref(false);
const damContext = ref("");

function onDamBrowse(show, context = "attach") {
    modalDamBrowser.show();
    showDamBrowser.value = true;
    damContext.value = context;
}

function onDamClosing() {
    showDamBrowser.value = false;
}

function onDamClose() {
    modalDamBrowser.hide();
}

function onAddImage(image) {
    bodytextEditor.value.instance.execute('insertImage', { source: image.publicUrl+ "/" + image.url + "?uid=" + image.remote_id });
    let lastRange = bodytextEditor.value.instance.ui.editor.model.document.selection.getLastRange();
    bodytextEditor.value.instance.model.change( writer => {
        writer.setSelection( writer.createPositionAfter(  bodytextEditor.value.instance.ui.editor.model.document.selection.getSelectedElement()) );
    } );
}

// Related
const showRelatedBrowser = ref(false);

function onRelatedBrowse() {
    modalRelatedBrowser.show();
    showRelatedBrowser.value = true;
}

function onRelatedClose() {
    modalRelatedBrowser.hide();
}

function onRelatedClosing() {
    showRelatedBrowser.value = false;
}

function onAddRelated(remote) {
    if(!remoteDetail.value.related.find((item) => {
        return item.uid == remote.uid;
    })) {
        remoteDetail.value.related.push({
            uid: remote.uid,
            datetime: remote.datetime,
            endtime: remote.endtime,
            hidden: remote.hidden,
            sorting: 9999,
            starttime: remote.starttime,
            title: remote.title,
        });
    }
}

const enabledDate = ref(true);

function onStartTimeChange(value) {
    if(value) {
        publishedStore.remoteDetail.datetime = value;
        enabledDate.value = false;
    } else {
        enabledDate.value = true;
        publishedStore.remoteDetail.datetime = null;
    }
}

// Publishing
const showPublish = ref(false);

function publishNews() {
    if (document.getElementsByName("remote-edit")[0].checkValidity()) {
        publishedStore.remoteDetail.bodytext = editorData;
        showPublish.value = true;
    } else {
        document.getElementsByName("remote-edit")[0].reportValidity();
    }
    //saveNewsAndClose();
}

function onPublishClose(value) {
    showPublish.value = false;
    if(value == 'done') {
        closeNews();
    }
}
</script>

<template>
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Modifica pubblicazione su <mark>{{ newsPaperStore.getNewspaperName(route.params.newspaperid) }}</mark></h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
          <li class="breadcrumb-item active">News</li>
        </ol>
      </div>
    </div>
  </div>
</section>

<section class="content">
    <div class="container-fluid">
        <div class="card card-default">
            <form name="remote-edit" class="m-3">
                <div class="form-group">
                    <label for="title">Titolo</label>
                    <input required type="text" class="form-control" id="title" v-model="remoteDetail.title" @keydown.enter="(e) => e.preventDefault()">
                </div>
                <div class="form-group">
                    <label for="short">Sottotitolo</label>
                    <input type="text" class="form-control" id="short" v-model="remoteDetail.short" @keydown.enter="(e) => e.preventDefault()">
                </div>
                <div class="form-group">
                    <label>Testo</label>
                    <div id="editor-container">
                        <ckeditor ref="bodytextEditor" :disabled=" (remoteDetail.type == 0) ? false : true " :editor="editor" v-model="editorData" :config="bodytextEditorConfig" @ready="onBodytextEditorReady()"></ckeditor>
                    </div>
                </div>
                <div class="form-group">
                    <label for="short">Parole chiave <small>(separate da ,)</small></label>
                    <input type="text" :disabled=" (remoteDetail.type == 0) ? false : true " class="form-control" id="keywords" v-model="remoteDetail.keywords" @keydown.enter="(e) => e.preventDefault()">
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="title">Data</label>
                            <datepicker required v-model="remoteDetail.datetime" :disabled="!enabledDate" id="datetime" locale="it" format="dd/MM/yyyy HH:mm" auto-apply :close-on-auto-apply="false" partial-flow :flow="['calendar', 'time']" :text-input="textInputOptions" />
                        </div>
                    </div>
                    
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="author">Autore</label>
                            <input type="text" :disabled=" (remoteDetail.type == 0) ? false : true " class="form-control" v-model="remoteDetail.author" id="author" @keydown.enter="(e) => e.preventDefault()" />
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="title">Data inizio</label>
                            <!-- input type="text" class="form-control" id="starttime" v-model="draftDetail.starttime" -->
                            <datepicker v-model="remoteDetail.starttime" locale="it" format="dd/MM/yyyy HH:mm" auto-apply :close-on-auto-apply="false" partial-flow :flow="['calendar', 'time']" :text-input="textInputOptions" @update:model-value="onStartTimeChange" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="title">Data fine</label>
                            <!-- input type="text" class="form-control" id="endtime" v-model="draftDetail.endtime" -->
                            <datepicker v-model="remoteDetail.endtime" locale="it" format="dd/MM/yyyy HH:mm" auto-apply :close-on-auto-apply="false" partial-flow :flow="['calendar', 'time']" :text-input="textInputOptions" />
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <label>Posizione</label>
                        <div class="form-group">
                        <div v-for="(position, index) in newsPaperStore.positions" :key="position.id">
                            <div class="icheck-primary d-inline">
                                <input type="checkbox" :id="position.id" :true-value="[]" v-model="remoteDetail.positions" :value="position.id">
                                <label :for="position.id">{{position.name}}</label>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label></label>
                        <div class="form-group clearfix">
                            <div  class="icheck-primary d-inline">
                                <input type="checkbox" id="premium" true-value="1" false-value="0" v-model="remoteDetail.premium">
                                <label for="premium">PREMIUM News</label>
                            </div>
                        </div>

                        <div class="form-group clearfix">
                            <div  class="icheck-primary d-inline">
                                <input type="checkbox"  id="hidden" true-value="1" false-value="0" v-model="remoteDetail.hidden">
                                <label for="hidden">Nascondi</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                    <div class="form-group">
                        <label for="title">Data inizio boxatura</label>
                        <datepicker v-model="remoteDetail.tx_ttnewsextra_boxed_start" locale="it" format="dd/MM/yyyy HH:mm" auto-apply :close-on-auto-apply="false" partial-flow :flow="['calendar', 'time']" :text-input="textInputOptions" />
                    </div>
                    </div>
                    <div class="col-md-6">
                    <div class="form-group">
                        <label for="title">Data fine boxatura</label>
                        <datepicker v-model="remoteDetail.tx_ttnewsextra_boxed_end" locale="it" format="dd/MM/yyyy HH:mm" auto-apply :close-on-auto-apply="false" partial-flow :flow="['calendar', 'time']" :text-input="textInputOptions" />
                    </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <label>Immagini</label>
                        <dam-images :store="publishedStore" :media_file_path="'/published'" @ondambrowse="onDamBrowse(true, 'attach')" />
                        
                    </div>
                    <div class="col-md-6">
                        <label>Allegati</label>
                        <dam-files :store="publishedStore" :media_file_path="'/published'" @ondambrowse="onDamBrowse(true, 'file')" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 ">
                        <label>Notizie correlate</label>
                        <RelatedRemoteNews  @onrelatedbrowse="onRelatedBrowse(true)" v-model="remoteDetail.related" />
                    </div>
                </div>
                <div class="mt-3 mb-2 row justify-content-end gx-5">
                    <div class="col-auto">
                        <div class="btn btn-secondary" @click="closeNews()">Chiudi</div>
                    </div>
                    <div class="col-auto mb-1">
                        <div class="btn btn-secondary" @click="saveNews()">Salva</div>
                    </div>
                    <div class="col-auto mb-1">
                        <div class="btn btn-secondary" @click="saveNewsAndClose()">Salva e Chiudi</div>
                    </div>
                    <div class="col-auto">
                        <div class="btn btn-primary" @click="publishNews()">Salva e Pubblica</div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
<div class="modal fade" ref="damBrowser"  tabindex="-1" role="dialog" data-keyboard="false" >
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
                </button>
            </div>
    
            <div class="modal-body">
                <DamImageBrowser ref="modal_browser" :show="showDamBrowser" :store="publishedStore" @addimage="onAddImage" @hidebsmodal="onDamClose" :context="damContext" />
            </div>
        </div>
    </div>
</div>
<div class="modal fade" ref="relatedBrowser" tabindex="-1" role="dialog" data-keyboard="false">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
                </button>
            </div>

            <div class="modal-body">
                <RelatedRemoteBrowser ref="modal_related_browser" :show="showRelatedBrowser" :language="remoteDetail.language_id" :remote_id="remoteDetail.uid" @hidebsmodal="onRelatedClose" @addrelated="onAddRelated" />
            </div>
        </div>
    </div>
</div>

<!-- BEGIN: Modal Publish -->
<PublishPublished id="modal_publish" ref="modal_publish" :show="showPublish" v-if="newsPaperStore.selectedNewspaper" @modalAction="(value) => onPublishClose(value)" />
<!-- BEGIN: Modal Publish -->

</template>