<script setup>
import { useAuthStore } from '@/stores';
import DamImageBrowserVue from '@/components/newspaper/DamImageBrowser.vue';
const authStore = useAuthStore();


</script>

<template>
  <nav v-if="authStore.user" class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <router-link v-role="'User Manager'" to="/admin/users" class="nav-link">Amministrazione</router-link>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <router-link v-role="'News Manager'" to="/draft" class="nav-link">News</router-link>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <a v-role="'News Manager'" class="nav-link" @click="onDamBrowse">Media</a>
      </li>
      
      <li class="nav-item dropdown  d-sm-none">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Menù
        </a>
        
        <div  class="dropdown-menu" aria-labelledby="navbarDropdown2">
          <router-link v-role="'User Manager'" to="/admin/users" class="nav-link">Amministrazione</router-link>
          <router-link v-role="'News Manager'" to="/draft" class="nav-link">News</router-link>
          <a v-role="'News Manager'" class="nav-link" @click="onDamBrowse">Media</a>
        </div>
      </li>

    </ul>
    
    
   
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
          <a class="nav-link" data-widget="fullscreen" href="#" role="button">
          <i class="fas fa-expand-arrows-alt"></i>
          </a>
      </li>
          <!--
          <li class="nav-item">
          <a class="nav-link" data-widget="control-sidebar" data-controlsidebar-slide="true"  class="dropdown-item" href="#" @click.prevent="authStore.logout()" role="button">
          <i class="fas fa-user-slash"></i>
          </a>
         </li>
         -->
         <li class="nav-item dropdown ">
          <a class="nav-link" data-toggle="dropdown" href="#" aria-expanded="true">
          <i class="fas fa-user"></i>
         
          </a>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right " style="left: inherit; right: 0px;">
          <span class="dropdown-item dropdown-header">{{ authStore.user.first_name }} {{ authStore.user.last_name }}</span>
          <div class="dropdown-divider"></div>
          <router-link to="/user/profile" class="dropdown-item"><i class="fas fa-user-cog mr-2"></i>Modifica profilo</router-link>
          <router-link to="/user/notifications" class="dropdown-item"><i class="fas fa-comments mr-2"></i>Elenco notifiche</router-link>
          <div class="dropdown-divider"></div>
          <a href="#" @click.prevent="authStore.logout()" class="dropdown-item">
          <i class="fas fa-sign-out-alt mr-2"></i>Esci
          
          </a>
          <div class="dropdown-divider"></div>
          
          <div class="dropdown-divider"></div>
          
          </div>
          </li>
    </ul>
</nav>


<div class="modal fade" ref="damBrowser"  tabindex="-1" role="dialog" data-keyboard="false" >
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Media</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
              </button>
            </div>
          
            <div class="modal-body">
              <DamImageBrowserVue :show="showDamBrowser" closing="" context="upload" />
            </div>
        </div>
    </div>
</div>


</template>
<style>
.nav-link {
  cursor: pointer;
}
</style>


<script>
export default {
  async mounted() {
    this.modalDamBrowser = new bootstrap.Modal(this.$refs.damBrowser);
    this.modalDamBrowser._config.keyboard = false;
    $(this.modalDamBrowser._element).on('hide.bs.modal', this.onDamClosing);
  },
  data() {
    return {
      showDamBrowser: false
    };
  },
  methods: {
    onDamBrowse() {
      this.showDamBrowser = true;
      this.modalDamBrowser.show();
    },
    onDamClosing() {
      this.showDamBrowser = false;
    },
  }
}
</script>
